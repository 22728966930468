import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Gym } from '../models/gym.model';
import { GymAlerts as GymAlertSettings } from '../models/gym-alert-settings.model';

@Injectable({
  providedIn: 'root'
})
export class GymService {

  constructor(private http: HttpClient) {
  }

  getGym() {
    return this.http.get<Gym>(`${environment.baseUrl}/gym`)
  }

  updateGym(gym: any) {
    return this.http.put(`${environment.baseUrl}/gym`, gym)
  }

  getGymAlerts() {
    return this.http.get<GymAlertSettings>(`${environment.baseUrl}/gym/alert-settings`)
  }

  updateGymAlerts(gym: any) {
    return this.http.put(`${environment.baseUrl}/gym/alert-settings`, gym)
  }
}
