<form [formGroup]="settingsGeneralForm" (ngSubmit)="onSubmit()" class="mb-16">
    <!-- General -->
    <div class="bg-white border border-gray-200 rounded-lg overflow-hidden mt-8">
        <div class="bg-gray-50 flex justify-between items-center px-4 py-3 cursor-pointer"
            [class.border-b]="generalTabOpen" (click)="toggleGeneralTab()">
            <div class="flex flex-col">
                <span class="font-medium text-lg text-gray-500">
                    Business details
                </span>
                <span class="text-sm text-gray-500">
                    Boring but important.
                </span>
            </div>
            <div>
                <chevron-up-outline-icon *ngIf="!generalTabOpen" class="h-5 w-5"></chevron-up-outline-icon>
                <chevron-down-outline-icon *ngIf="generalTabOpen" class="h-5 w-5"></chevron-down-outline-icon>
            </div>
        </div>
        <div *ngIf="generalTabOpen" class="flex flex-col gap-4 m-6">
            <div class="flex flex-col sm:flex-row gap-2 pb-5 border-b">
                <span class="font-medium text-gray-500 text-sm basis-1 sm:basis-[280px]">
                    Business name
                </span>
                <div class="flex basis-1 sm:basis-[512px]">
                    <input type="text" formControlName="name"
                        class="disabled:text-gray-700 border border-gray-300 focus:border-primary-300 focus:outline-0 focus:ring focus:ring-primary-100 text-sm px-4 py-3 rounded-lg w-full bg-gray-50 basis-[512px]" />
                    <question-mark-circle-outline-icon [size]="22" matTooltip="Contact FightCloud to update" matTooltipPosition="above"
                        class="text-gray-400 -ml-8 mt-3">
                    </question-mark-circle-outline-icon>
                </div>
            </div>
            <div class="flex flex-col sm:flex-row gap-2 pb-5 border-b">
                <span class="font-medium text-gray-500 text-sm basis-1 sm:basis-[280px]">
                    Time zone
                </span>
                <select formControlName="timezone"
                    class="border border-gray-300 focus:border-primary-300 focus:outline-0 focus:ring focus:ring-primary-100 text-sm px-4 py-3 rounded-lg w-full hover:bg-gray-50 basis-1 sm:basis-[512px]">
                    <option *ngFor="let tz of timezones" [value]="tz">{{tz}}</option>
                </select>
            </div>
            <div class="flex flex-col sm:flex-row gap-2 pb-5 border-b">
                <span class="font-medium text-gray-500 text-sm basis-1 sm:basis-[280px]">
                    ABN / ACN
                </span>
                <input type="text" formControlName="abn"
                    class="border border-gray-300 focus:border-primary-300 focus:outline-0 focus:ring focus:ring-primary-100 text-sm px-4 py-3 rounded-lg w-full hover:bg-gray-50 basis-1 sm:basis-[512px]" />
            </div>
            <div class="flex flex-col sm:flex-row gap-2 pb-5 border-b">
                <span class="font-medium text-gray-500 text-sm basis-1 sm:basis-[280px]">
                    Website
                </span>
                <div class="flex basis-1 sm:basis-[512px]">
                    <span
                        class="inline-flex items-center px-3 text-sm text-gray-500 border border-r-0 border-gray-300 rounded-l-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                        https://
                    </span>
                    <input type="text" formControlName="website"
                        class="border border-gray-300 focus:border-primary-300 focus:outline-0 focus:ring focus:ring-primary-100 text-sm px-4 py-3 rounded-none rounded-r-lg w-full hover:bg-gray-50" />
                </div>
            </div>
            <div class="flex flex-col sm:flex-row gap-2 pb-5 border-b">
                <span class="font-medium text-gray-500 text-sm basis-1 sm:basis-[280px]">
                    Public phone number
                </span>
                <div class="flex flex-col gap-2 basis-1 sm:basis-[512px]">
                    <input type="text" formControlName="phone"
                        class="border border-gray-300 focus:border-primary-300 focus:outline-0 focus:ring focus:ring-primary-100 text-sm px-4 py-3 rounded-lg w-full hover:bg-gray-50" />
                </div>
            </div>
            <div class="flex flex-col sm:flex-row gap-2 pb-5 border-b">
                <span class="font-medium text-gray-500 text-sm basis-1 sm:basis-[280px]">
                    Business address
                </span>
                <div class="flex flex-col gap-2 basis-1 sm:basis-[512px]">
                    <input type="text" formControlName="address"
                        class="border border-gray-300 focus:border-primary-300 focus:outline-0 focus:ring focus:ring-primary-100 text-sm px-4 py-3 rounded-lg w-full hover:bg-gray-50" />
                    <span class="text-sm text-gray-500">
                        We won't validate this address, so make sure it's correct.
                    </span>
                </div>
            </div>
            <div class="flex flex-col sm:flex-row gap-2">
                <span class="font-medium text-gray-500 text-sm basis-1 sm:basis-[280px]">
                    Support email
                </span>
                <div class="flex flex-col gap-2 basis-1 sm:basis-[512px]">
                    <input type="email" formControlName="supportEmail"
                        [ngClass]="{'border focus:outline-0 focus:ring  text-sm px-4 py-3 rounded-lg w-full hover:bg-gray-50': true, 'border-gray-300 focus:border-primary-300 focus:ring-primary-100': !submitted || settingsGeneralForm.get('supportEmail')?.valid, 'border-error-300 focus:border-error-300 focus:ring-error-100': submitted && !settingsGeneralForm.get('supportEmail')?.valid }"/>
                    <span *ngIf="submitted && !settingsGeneralForm.get('supportEmail')?.valid" class="text-sm text-error-500">Please enter a valid email address</span>
                    <span class="text-sm text-gray-500">
                        This email is provided to your students if they need to contact you. e.g. for billing enquiries.
                    </span>
                </div>
            </div>
        </div>
    </div>
    <!-- Socials -->
    <div class="bg-white border border-gray-200 rounded-lg overflow-hidden mt-8">
        <div class="bg-gray-50 flex justify-between items-center px-4 py-3 cursor-pointer"
            [class.border-b]="socialsTabOpen" (click)="toggleSocialsTab()">
            <div class="flex flex-col">
                <span class="font-medium text-lg text-gray-500">
                    Social media
                </span>
                <span class="text-sm text-gray-500">
                    Shamelessly plug your socials to students.
                </span>
            </div>
            <div>
                <chevron-up-outline-icon *ngIf="!socialsTabOpen" class="h-5 w-5"></chevron-up-outline-icon>
                <chevron-down-outline-icon *ngIf="socialsTabOpen" class="h-5 w-5"></chevron-down-outline-icon>
            </div>
        </div>
        <div *ngIf="socialsTabOpen" class="flex flex-col m-6 gap-4">
            <div class="flex flex-col sm:flex-row gap-2 pb-5 border-b">
                <span class="font-medium text-gray-500 text-sm basis-1 sm:basis-[280px]">
                    Facebook
                </span>
                <div class="flex basis-1 sm:basis-[512px]">
                    <span
                        class="inline-flex items-center px-3 text-sm text-gray-500 border border-r-0 border-gray-300 rounded-l-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                        facebook.com/
                    </span>
                    <input type="text" formControlName="facebook"
                        class="border border-gray-300 focus:border-primary-300 focus:outline-0 focus:ring focus:ring-primary-100 text-sm px-4 py-3 rounded-none rounded-r-lg w-full hover:bg-gray-50"
                    />
                </div>
            </div>
            <div class="flex flex-col sm:flex-row gap-2 pb-5 border-b">
                <span class="font-medium text-gray-500 text-sm basis-1 sm:basis-[280px]">
                    Instagram
                </span>
                <div class="flex basis-1 sm:basis-[512px]">
                    <span
                        class="inline-flex items-center px-3 text-sm text-gray-500 border border-r-0 border-gray-300 rounded-l-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                        @
                    </span>
                    <input type="text" formControlName="instagram"
                        class="border border-gray-300 focus:border-primary-300 focus:outline-0 focus:ring focus:ring-primary-100 text-sm px-4 py-3 rounded-none rounded-r-lg w-full hover:bg-gray-50"
                    />
                </div>
            </div>
            <div class="flex flex-col sm:flex-row gap-2 pb-5 border-b">
                <span class="font-medium text-gray-500 text-sm basis-1 sm:basis-[280px]">
                    TikTok
                </span>
                <div class="flex basis-1 sm:basis-[512px]">
                    <span
                        class="inline-flex items-center px-3 text-sm text-gray-500 border border-r-0 border-gray-300 rounded-l-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                        @
                    </span>
                    <input type="text" formControlName="tiktok"
                        class="border border-gray-300 focus:border-primary-300 focus:outline-0 focus:ring focus:ring-primary-100 text-sm px-4 py-3 rounded-none rounded-r-lg w-full hover:bg-gray-50"
                    />
                </div>
            </div>
            <div class="flex flex-col sm:flex-row gap-2 pb-5 border-b">
                <span class="font-medium text-gray-500 text-sm basis-1 sm:basis-[280px]">
                    Youtube
                </span>
                <div class="flex basis-1 sm:basis-[512px]">
                    <span
                        class="inline-flex items-center px-3 text-sm text-gray-500 border border-r-0 border-gray-300 rounded-l-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                        @
                    </span>
                    <input type="text" formControlName="youtube"
                        class="border border-gray-300 focus:border-primary-300 focus:outline-0 focus:ring focus:ring-primary-100 text-sm px-4 py-3 rounded-none rounded-r-lg w-full hover:bg-gray-50"
                    />
                </div>
            </div>
            <div class="flex flex-col sm:flex-row gap-2">
                <span class="font-medium text-gray-500 text-sm basis-1 sm:basis-[280px]">
                    Twitter
                </span>
                <div class="flex basis-1 sm:basis-[512px]">
                    <span
                        class="inline-flex items-center px-3 text-sm text-gray-500 border border-r-0 border-gray-300 rounded-l-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                        twitter.com/
                    </span>
                    <input type="text" formControlName="twitter"
                        class="border border-gray-300 focus:border-primary-300 focus:outline-0 focus:ring focus:ring-primary-100 text-sm px-4 py-3 rounded-none rounded-r-lg w-full hover:bg-gray-50"
                    />
                </div>
            </div>
        </div>
    </div>
    <ng-container *ngIf="settingsGeneralForm.dirty">
        <app-save-footer buttonText="Save" [error]="submitted && !settingsGeneralForm.valid"></app-save-footer>
    </ng-container>
</form>
