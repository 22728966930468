import { Injectable } from '@angular/core';
import { BehaviorSubject, switchMap, tap } from 'rxjs';
import { User } from '../models/user.model';
import { AuthService } from '@auth0/auth0-angular';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  #userSubject = new BehaviorSubject<User | undefined>(undefined);

  constructor(private authService: AuthService, private http: HttpClient) { 
  }

  getUser() {
    if (this.#userSubject.value) {
      return this.#userSubject.asObservable();
    }

    return this.http.get<User>(`${environment.baseUrl}/user/me`).pipe(
      tap(user => this.#userSubject.next(user)),
      switchMap(() => this.#userSubject)
    )
  }

  logout() {
    this.authService.logout({
      logoutParams: { 
        returnTo: window.location.origin
      }
    });
    
    this.#userSubject.next(undefined);
  }
}
