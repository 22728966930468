import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { SessionCreateComponent } from './pages/session/session-create/session-create.component';
import { StudentCreateComponent } from './pages/student/student-create/student-create.component';
import { StudentIndexComponent } from './pages/student/student-index/student-index.component';
import { StudentIdComponent } from './pages/student/student-id/student-id.component';
import { StudentGeneralComponent } from './pages/student/student-id/student-general/student-general.component';
import { StudentTrainingComponent } from './pages/student/student-id/student-training/student-training.component';
import { StudentNotesComponent } from './pages/student/student-id/student-notes/student-notes.component';
import { StudentHistoryComponent } from './pages/student/student-id/student-history/student-history.component';
import { StudentMembershipDetailsComponent } from './pages/student/student-id/student-membership/student-membership-details/student-membership-details.component';
import { StudentDocumentsComponent } from './pages/student/student-id/student-documents/student-documents.component';
import { SessionIdComponent } from './pages/session/session-id/session-id.component';
import { SettingsIndexComponent } from './pages/settings/settings-index/settings-index.component';
import { SettingsGeneralComponent } from './pages/settings/settings-general/settings-general.component';
import { SettingsAlertsComponent } from './pages/settings/settings-alerts/settings-alerts.component';
import { SettingsDisciplinesComponent } from './pages/settings/settings-disciplines/settings-disciplines.component';
import { SettingsDocumentsComponent } from './pages/settings/settings-documents/settings-documents.component';
import { SettingsDocumentCreateComponent } from './pages/settings/settings-documents/settings-document-create/settings-document-create.component';
import { SettingsMembershipsComponent } from './pages/settings/settings-memberships/settings-memberships.component';
import { SettingsMembershipCreateComponent } from './pages/settings/settings-memberships/settings-membership-create/settings-membership-create.component';
import { StudentMembershipComponent } from './pages/student/student-id/student-membership/student-membership.component';

const routes: Routes = [
  { path: '', canActivate: [AuthGuard], children: [
      { path: '', component: DashboardComponent },
      { path: 'session/create', component: SessionCreateComponent },
      { path: 'session/edit/:id', component: SessionCreateComponent },
      { path: 'session/:id', component: SessionIdComponent },
      { path: 'student/create', component: StudentCreateComponent },
      { path: 'student', component: StudentIndexComponent },
      { path: 'student/:id', component: StudentIdComponent, children: [
        { path: '', redirectTo: 'general', pathMatch: 'full' },
        { path: 'general', component: StudentGeneralComponent },
        { path: 'training', component: StudentTrainingComponent },
        { path: 'notes', component: StudentNotesComponent },
        { path: 'history', component: StudentHistoryComponent },
        { path: 'membership', component: StudentMembershipComponent },
        { path: 'membership/:studentMembershipId', component: StudentMembershipDetailsComponent },
        { path: 'documents', component: StudentDocumentsComponent },
      ]},
      { path: 'settings', component: SettingsIndexComponent, children: [
        { path: '', redirectTo: 'general', pathMatch: 'full' },
        { path: 'general', component: SettingsGeneralComponent },
        { path: 'disciplines', component: SettingsDisciplinesComponent },
        { path: 'alerts', component: SettingsAlertsComponent },
        { path: 'documents', component: SettingsDocumentsComponent },
        { path: 'memberships', component: SettingsMembershipsComponent }
      ]},
      { path: 'settings/documents/create', component: SettingsDocumentCreateComponent},
      { path: 'settings/documents/edit/:id', component: SettingsDocumentCreateComponent},
      { path: 'settings/memberships/create', component: SettingsMembershipCreateComponent},
      { path: 'settings/memberships/edit/:id', component: SettingsMembershipCreateComponent}
    ]
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
