export interface AlertNotification {
    id: number;
    type: AlertType;
    studentId: number;
    firstName: string;
    lastName: string;
    email: string;
    mobile: string;
    privateCount?: number;
    groupCount?: number;
    grade?: string;
    discipline?: string;
    sessionDate?: string;
}

export enum AlertType {
    Grade = 1,
    Attendance = 2,
    Waiver = 3
}