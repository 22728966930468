<div class="w-full h-12 flex justify-between items-center border-b">
    <a routerLink="/" class="ml-4 flex gap-2">
        <img class="w-8 h-8" src="/assets/images/logo.svg"/> 
        <span class="font-medium text-2xl text-primary-700">FightCloud</span>
    </a>
    <div class="mr-4 flex items-center">
        <button [matMenuTriggerFor]="menu">
            <bars-3-outline-icon></bars-3-outline-icon>
        </button>
    </div>
</div>
<mat-menu class="w-full" #menu="matMenu">
    <a routerLink="/" mat-menu-item>
        <div class="flex items-center gap-2">
            <home-outline-icon [size]="20"></home-outline-icon>
            <span>Calendar</span>
        </div>
    </a>
    <a routerLink="/session/create" mat-menu-item>
        <div class="flex items-center gap-2">
            <calendar-outline-icon [size]="20"></calendar-outline-icon>
            <span>Sessions</span>
        </div>
    </a>
    <a routerLink="/student" mat-menu-item>
        <div class="flex items-center gap-2">
            <users-outline-icon [size]="20"></users-outline-icon>
            <span>Students</span>
        </div>
    </a>
    <a routerLink="/settings" mat-menu-item>
        <div class="flex items-center gap-2">
            <cog-6-tooth-outline-icon [size]="20"></cog-6-tooth-outline-icon>
            <span>Settings</span>
        </div>
    </a>
    <a (click)="logout()" mat-menu-item>
        <div class="flex items-center gap-2">
            <arrow-up-tray-outline-icon [size]="20" class="rotate-90"/>
            <span>Logout</span>
        </div>
    </a>
</mat-menu>