import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { ToasterType } from 'src/app/models/toaster.model';
import { GymService } from 'src/app/services/gym.service';
import { ToasterService } from 'src/app/services/toaster.service';

@Component({
  selector: 'app-settings-general-form',
  templateUrl: './settings-general-form.component.html',
  styleUrls: ['./settings-general-form.component.scss']
})
export class SettingsGeneralFormComponent implements OnInit {
  @Input() gym: any;

  settingsGeneralForm: FormGroup;
  generalTabOpen: boolean = true;
  socialsTabOpen: boolean = true;
  timezones: string[] = [];
  submitted = false;

  constructor(private fb: FormBuilder, private gymService: GymService, private toasterService: ToasterService) {
    this.settingsGeneralForm = this.fb.group({
      name: ['', Validators.required],
      timezone: ['', Validators.required],
      abn: [''],
      phone: [''],
      website: [''],
      address: [''],
      supportEmail: ['', Validators.email],
      facebook: [''],
      instagram: [''],
      tiktok: [''],
      youtube: [''],
      twitter: ['']
    });

    this.settingsGeneralForm.get('name')?.disable();
    let timezones  = moment.tz.zonesForCountry('AU');
    
    this.timezones = timezones.filter((tz: string) => {
      // filter out the random remote locations
      return !["Macquarie", "_", "Lindeman", "Eucla", "Currie"].some((v: string) => tz.includes(v));
    });

  }

  ngOnInit(): void {
    if (this.gym) {
      this.settingsGeneralForm.patchValue(this.gym);
    }
  }

  toggleGeneralTab() {
    this.generalTabOpen = !this.generalTabOpen;
  }

  toggleSocialsTab() {
    this.socialsTabOpen = !this.socialsTabOpen;
  }

  onSubmit() {
    this.submitted = true;
    if (this.settingsGeneralForm.valid) {
      this.gymService.updateGym(this.settingsGeneralForm.value).subscribe(() => this.toasterService.addToaster({type: ToasterType.Success, message: 'Settings updated'}));
      // hide the save footer
      this.settingsGeneralForm.markAsPristine();
    }
  }
}
