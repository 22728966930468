<div class="flex justify-end align-end gap-3 top-0 right-2 absolute pt-16 mt-2.5">
    <app-button-primary routerLink="create">
        <document-plus-outline-icon [size]="20"></document-plus-outline-icon>
        <span>
            Add document
        </span>
    </app-button-primary>
</div>

<div class="flex flex-col items-start self-stretch gap-6 py-8">
    <div class="flex flex-col justify-center items-start flex-1 flex-shrink-0">
        <div class="text-gray-900 font-normal text-xl">
            Active document templates
        </div>
        <div class="text-gray-500 font-normal text-sm">
            Documents required for all students to complete.
        </div>
    </div>
    <ng-container *ngIf="activeDocs.length > 0">
    <div class="w-full rounded-lg overflow-x-auto border border-gray-200 bg-white shadow-sm">
        <table class="w-full">
            <tbody class="rounded-lg">
                <ng-container *ngFor="let document of activeDocs; let last = last">
                    <tr class="{{ !last ? 'border-b border-gray-200' : '' }}">
                        <td class="flex py-4 px-6 items-center gap-3 self-stretch">
                            <div class="w-10 h-10 p-2.5 justify-center bg-primary-100 rounded-full overflow-hidden">
                                <document-outline-icon [size]="20" class="text-primary-600"></document-outline-icon>
                            </div>
                            <div>
                                <div class="whitespace-nowrap text-sm font-medium text-gray-900">{{ document.name
                                    }}
                                </div>
                                <div class="whitespace-nowrap text-sm text-gray-500">Version {{
                                    document.versions[0].version }}
                                </div>
                            </div>
                        </td>
                        <td class="w-full py-4 px-6">
                            <div class="hidden sm:block text-sm text-gray-900">{{ document.description }}</div>
                            <div class="text-sm text-gray-500">Valid as at {{ localDate(document.versions[0].createdAt)
                                }}</div>
                        </td>
                        <td class="p-4 flex justify-end gap-1 self-stretch text-gray-500">
                            <div class="p-2.5 cursor-pointer" [routerLink]="['edit', document.id]">
                                <pencil-outline-icon [size]="20" matTooltip="Edit"></pencil-outline-icon>
                            </div>
                            <div class="p-2.5 cursor-pointer" (click)="archiveDocument(document.id)">
                                <archive-box-outline-icon [size]="20" matTooltip="Archive"></archive-box-outline-icon>
                            </div>
                            <div class="p-2.5 cursor-pointer" (click)="downloadDocument(document, document.versions[0].version)">
                                <arrow-down-tray-outline-icon [size]="20" matTooltip="Download"></arrow-down-tray-outline-icon>
                            </div>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
    </ng-container>
    <div class="flex flex-col justify-center items-start flex-1 flex-shrink-0">
        <div class="text-gray-900 font-normal text-xl">
            Archived document templates
        </div>
        <div class="text-gray-500 font-normal text-sm">
            Documents not in active use.
        </div>
    </div>
    <ng-container *ngIf="archivedDocs.length > 0">
    <div class="w-full rounded-lg overflow-x-auto border border-gray-200 bg-white shadow-sm">
        <table class="w-full">
            <tbody class="rounded-lg">
                <ng-container *ngFor="let document of archivedDocs; let last = last">
                    <tr class="{{ !last ? 'border-b border-gray-200' : '' }}">
                        <td class="flex py-4 px-6 items-center gap-3 self-stretch">
                            <div class="w-10 h-10 p-2.5 justify-center bg-primary-100 rounded-full overflow-hidden">
                                <document-outline-icon [size]="20" class="text-primary-600"></document-outline-icon>
                            </div>
                            <div>
                                <div class="whitespace-nowrap text-sm font-medium text-gray-900">{{ document.name
                                    }}
                                </div>
                                <div class="whitespace-nowrap text-sm text-gray-500">Version {{
                                    document.versions[0].version }}
                                </div>
                            </div>
                        </td>
                        <td class="w-full py-4 px-6">
                            <div class="hidden sm:block text-sm text-gray-900">{{ document.description }}</div>
                            <div class="text-sm text-gray-500">Valid as at {{ localDate(document.versions[0].createdAt)
                                }}</div>
                        </td>
                        <td class="p-4 flex justify-end gap-1 self-stretch text-gray-500">
                            <div class="p-2.5 cursor-pointer" (click)="restoreDocument(document.id)">
                                <play-circle-outline-icon [size]="20" matTooltip="Restore"></play-circle-outline-icon>
                            </div>
                            <div class="p-2.5 cursor-pointer" (click)="downloadDocument(document, document.versions[0].version)">
                                <arrow-down-tray-outline-icon [size]="20" matTooltip="Download"></arrow-down-tray-outline-icon>
                            </div>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
    </ng-container>
    <div class="invisible p-4 w-[794px] fixed top-[100vh] ql-editor" #hiddenPdfDiv></div>
</div>