import { Component, Input } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss'],
})
export class BackButtonComponent {
  @Input() buttonText: string = 'Back';

  constructor(private location: Location) {}

  navigateBack() {
    this.location.back();
  }
}