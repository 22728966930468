import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Document } from 'src/app/models/document.model';
import { ToasterType } from 'src/app/models/toaster.model';
import { DocumentService } from 'src/app/services/document.service';
import { ToasterService } from 'src/app/services/toaster.service';

@Component({
  selector: 'app-document-create-form',
  templateUrl: './document-create-form.component.html',
  styleUrls: ['./document-create-form.component.scss']
})
export class DocumentCreateFormComponent implements OnInit {
  @Input() document: Document | undefined;
  @Input() documentId: number | null = null;

  editing = false;
  submitted = false;
  initialised = false
  documentCreateForm!: FormGroup;

  constructor(private documentService: DocumentService, private toasterService: ToasterService, private router: Router) { }

  ngOnInit(): void {
    this.documentCreateForm = new FormGroup({
      name: new FormControl<string>(this.document?.name ?? '', Validators.required),
      description: new FormControl<string>(this.document?.description ?? ''),
      signatureRequired: new FormControl<boolean>(this.document?.versions[0].signatureRequired ?? false),
      content: new FormControl<string>(this.document?.versions[0].content ?? '', Validators.required)
    });

    if (this.document) {
      this.editing = true;
      const nameControl = this.documentCreateForm.get('name');
      nameControl?.disable();
      nameControl?.clearValidators();
      nameControl?.updateValueAndValidity();
    }
  }
  
  onSubmit(): void {
    this.submitted = true;
    if (this.documentCreateForm.valid) {
      if (this.editing) {
        this.documentService.updateDocument(this.documentId!, this.documentCreateForm.value).subscribe(result => {
          this.toasterService.addToaster({type: ToasterType.Success, message: 'Document template updated'})
          this.router.navigate(['settings', 'documents']);
        })
      } else {
        this.documentService.createDocument(this.documentCreateForm.value).subscribe(result => {
          this.toasterService.addToaster({type: ToasterType.Success, message: 'Document template created'})
          this.router.navigate(['settings', 'documents']);
        })
      }
    }
  }

  onInitialValueSet() {
    this.documentCreateForm.markAsPristine();
  }

}
