import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Document } from 'src/app/models/document.model';
import { DocumentService } from 'src/app/services/document.service';

@Component({
  selector: 'app-settings-document-create',
  templateUrl: './settings-document-create.component.html',
  styleUrls: ['./settings-document-create.component.scss']
})
export class SettingsDocumentCreateComponent implements OnInit {
  loaded: boolean = false;
  editing: boolean = false;
  document: Document | undefined = undefined;
  documentId: number | null = null;

  constructor(private route: ActivatedRoute, private router: Router, private documentService: DocumentService) {

  }

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');

    if (id) {
      this.documentId = +id;
      this.editing = true;
      this.documentService.getDocument(+id).subscribe(response => {
        this.document = response;
        this.loaded = true;
      })
    }
    else {
      this.loaded = true;
    }
  }
}
