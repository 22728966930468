<div class="flex justify-end align-end gap-3 top-0 right-2 absolute pt-16 mt-2.5" *ngIf="billingCompleted">
    <app-button-primary routerLink="create">
        <plus-small-outline-icon [size]="20"></plus-small-outline-icon>
        <span>
            Add Membership
        </span>
    </app-button-primary>
</div>
<ng-container *ngIf="loading">
    <app-loader  class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"></app-loader>
</ng-container>
<ng-container *ngIf="!loading">
    <div class="flex flex-col items-start self-stretch gap-6 py-8" *ngIf="billingCompleted">
        <div class="flex flex-col justify-center items-start flex-1 flex-shrink-0">
            <div class="text-gray-900 font-normal text-xl">
                Active membership plans
            </div>
            <div class="text-gray-500 font-normal text-sm">
                Membership plans available for student subscription.
            </div>
        </div>
        <ng-container *ngIf="activeMemberships.length > 0">
        <div class="w-full rounded-lg overflow-x-auto border border-gray-200 bg-white shadow-sm">
            <table class="w-full">
                <tbody class="rounded-lg">
                    <ng-container *ngFor="let membership of activeMemberships; let last = last">
                        <tr class="flex {{ !last ? 'border-b border-gray-200' : '' }}">
                            <td class="flex basis-[350px] py-4 px-6 items-center gap-3 self-stretch">
                                <div class="w-10 h-10 p-2.5 justify-center bg-primary-100 rounded-full overflow-hidden">
                                    <currency-dollar-outline-icon [size]="20" class="text-primary-600"></currency-dollar-outline-icon>
                                </div>
                                <div>
                                    <div class="whitespace-nowrap text-sm font-medium text-gray-900">{{ membership.name
                                        }}
                                    </div>
                                    <div class="whitespace-nowrap text-sm text-gray-500">Version {{
                                        membership.versions[0].version }}
                                    </div>
                                </div>
                            </td>
                            <td class="flex flex-col py-4 px-6">
                                <div class="hidden sm:block text-sm text-gray-900">{{ membership.description }}</div>
                                <div class="text-sm text-gray-500">
                                    Valid as at {{ localDate(membership.versions[0].createdAt)}} 
                                    <span *ngIf="membership.activeCount > 0 && membership.cancellingCount > 0">({{membership.activeCount}} active, {{membership.cancellingCount}} cancelling)</span>
                                    <span *ngIf="membership.activeCount > 0 && membership.cancellingCount == 0">({{membership.activeCount}} active)</span>
                                    <span *ngIf="membership.activeCount == 0 && membership.cancellingCount > 0">({{membership.cancellingCount}} cancelling)</span>
                                </div>
                            </td>
                            <td class="p-4 flex justify-end gap-1 text-gray-500 grow">
                                <div class="p-2.5 cursor-pointer" [routerLink]="['edit', membership.id]">
                                    <pencil-outline-icon [size]="20" matTooltip="Edit"></pencil-outline-icon>
                                </div>
                                <div class="p-2.5 cursor-pointer" (click)="archiveMembership(membership.id)">
                                    <archive-box-outline-icon [size]="20" matTooltip="Archive"></archive-box-outline-icon>
                                </div>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
        </ng-container>
        <div class="flex flex-col justify-center items-start flex-1 flex-shrink-0">
            <div class="text-gray-900 font-normal text-xl">
                Archived membership plans
            </div>
            <div class="text-gray-500 font-normal text-sm">
                Membership plans not in active use.
            </div>
        </div>
        <ng-container *ngIf="archivedMemberships.length > 0">
            <div class="w-full rounded-lg overflow-x-auto border border-gray-200 bg-white shadow-sm">
                <table class="w-full">
                    <tbody class="rounded-lg">
                        <ng-container *ngFor="let membership of archivedMemberships; let last = last">
                            <tr class="{{ !last ? 'border-b border-gray-200' : '' }}">
                                <td class="flex py-4 px-6 items-center gap-3 self-stretch">
                                    <div class="w-10 h-10 p-2.5 justify-center bg-primary-100 rounded-full overflow-hidden">
                                        <currency-dollar-outline-icon [size]="20" class="text-primary-600"></currency-dollar-outline-icon>
                                    </div>
                                    <div>
                                        <div class="whitespace-nowrap text-sm font-medium text-gray-900">{{ membership.name
                                            }}
                                        </div>
                                        <div class="whitespace-nowrap text-sm text-gray-500">Version {{
                                            membership.versions[0].version }}
                                        </div>
                                    </div>
                                </td>
                                <td class="w-full py-4 px-6">
                                    <div class="hidden sm:block text-sm text-gray-900">{{ membership.description }}</div>
                                    <div class="text-sm text-gray-500">Valid as at {{ localDate(membership.versions[0].createdAt)
                                        }}</div>
                                </td>
                                <td class="p-4 flex justify-end gap-1 self-stretch text-gray-500">
                                    <div class="p-2.5 cursor-pointer" (click)="restoreMembership(membership.id)">
                                        <play-circle-outline-icon [size]="20" matTooltip="Restore"></play-circle-outline-icon>
                                    </div>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
            </ng-container>
    </div>
    <div class="flex flex-col items-start self-stretch gap-6 py-8"  *ngIf="!billingCompleted">
        <p>Billing not setup. Connect with stripe to start making memberships.</p>
        <app-button-primary (click)="setupStripe()" [loading]="loadingStripe">Setup Stripe</app-button-primary>
    </div>
</ng-container>