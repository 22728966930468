import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Document } from 'src/app/models/document.model';
import { DocumentService } from 'src/app/services/document.service';
import { utcToLocalDate } from 'src/app/lib/date-format';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmModalComponent } from 'src/app/components/modals/confirm-modal/confirm-modal.component';
import html2canvas from 'html2canvas';
import jspdf from 'jspdf';

@Component({
  selector: 'app-settings-documents',
  templateUrl: './settings-documents.component.html',
  styleUrls: ['./settings-documents.component.scss']
})
export class SettingsDocumentsComponent implements OnInit {
  activeDocs: Document[] = [];
  archivedDocs: Document[] = [];
  @ViewChild("hiddenPdfDiv") hiddenPdfDiv!: ElementRef<HTMLCanvasElement>;

  constructor(private documentService: DocumentService, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.documentService.getDocuments().subscribe(results => {
      this.activeDocs = results.filter((d) => !d.isArchived);
      this.archivedDocs = results.filter((d) => d.isArchived);
    })
  }

  downloadDocument(doc: Document, version: number) {
    this.documentService.downloadDocument(doc.id, version).subscribe((html) => {
      this.hiddenPdfDiv.nativeElement.classList.remove('invisible');
      this.hiddenPdfDiv.nativeElement.innerHTML = html;
      setTimeout(() => {
        html2canvas(this.hiddenPdfDiv.nativeElement).then(canvas => {
          this.hiddenPdfDiv.nativeElement.classList.add('invisible');
          var imgData = canvas.toDataURL('image/png');
          var imgWidth = 210; 
          var pageHeight = 295;  
          var imgHeight = canvas.height * imgWidth / canvas.width;
          var heightLeft = imgHeight;
          var pdf = new jspdf('p', 'mm');
          var position = 10;

          pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;

          while (heightLeft >= 0) {
            position += heightLeft - imgHeight;
            pdf.addPage();
            pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
          }

          pdf.save(`${doc.name}_v${version}.pdf`);
        });
      }, 0);
    });
  }

  archiveDocument(id: number) {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      width: '400px',
      data: {
        title: "Archive document template",
        content: "Are you sure you want to archive this document? Archiving this document template will also archive any signed documents by your students.",
        yesText: "Yes, archive it",
        noText: "Cancel",
        yesColourRed: true
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.documentService.archiveDocument(id).subscribe(() => {
          const documentToArchive = this.activeDocs.find(doc => doc.id === id);
          if (documentToArchive) {
            this.activeDocs = this.activeDocs.filter(doc => doc.id !== id);
            this.archivedDocs.push(documentToArchive);
          }
        });
      }
    });
  }

  restoreDocument(id: number) {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      width: '400px',
      data: {
        title: "Restore document template",
        content: "Are you sure you want to restore this document? Restoring this document template will also restore any signed documents by your students.",
        yesText: "Restore",
        noText: "Cancel",
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.documentService.restoreDocument(id).subscribe(() => {
          const documentToRestore = this.archivedDocs.find(doc => doc.id === id);
          if (documentToRestore) {
            this.archivedDocs = this.archivedDocs.filter(doc => doc.id !== id);
            this.activeDocs.push(documentToRestore);
          }
        });
      }
    });
  }

  localDate(date: any){
    return utcToLocalDate(date);
  }
}
