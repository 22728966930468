import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { StudentHistory } from '../models/student-history.model';

@Injectable({
  providedIn: 'root'
})
export class StudentHistoryService {

  constructor(private http: HttpClient) { }

  getStudentHistory(studentId: number) {
    return this.http.get<StudentHistory[]>(`${environment.baseUrl}/studenthistory/${studentId}`);
  }
}
