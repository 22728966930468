import { Component, Input } from '@angular/core';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmModalComponent } from 'src/app/components/modals/confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-save-footer',
  templateUrl: './save-footer.component.html',
  styleUrls: ['./save-footer.component.scss']
})
export class SaveFooterComponent {
  @Input() buttonText: string = 'Update';
  @Input() error: boolean = false;
  @Input() loading: boolean = false;

  constructor(private location: Location) {}

  onCancel(): void{
    this.location.back();
  }
}


