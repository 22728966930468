import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, Inject } from '@angular/core';

@Component({
  selector: 'app-check-in-modal',
  templateUrl: './check-in-modal.component.html',
  styleUrls: ['./check-in-modal.component.scss']
})
export class CheckInModalComponent {
  constructor(public dialogRef: DialogRef<string>, @Inject(DIALOG_DATA) public data: {name: string}) {
    setTimeout(() => this.dialogRef.close(), 2000);
  }
}
