<div class="py-2.5">
    <h1 class="font-medium text-3xl mb-6">Settings</h1>
    <div class="mt-4 pb-4 flex border-b border-gray-200 sm:hidden">
        <mat-select [(ngModel)]="currentRoute" (selectionChange)="routeChange($event)" [hideSingleSelectionIndicator]="true" class="border border-gray-300 focus:border-primary-300 focus:outline-0 focus:ring focus:ring-primary-100 text-sm pr-4 py-[10px] rounded-lg w-full hover:bg-gray-50">
          <mat-select-trigger>
          <span class="pl-4">
              {{currentRoute.title}}
          </span>
          </mat-select-trigger>
          <mat-option *ngFor="let route of routes" [value]="route" class="-ml-2">{{route.title}}</mat-option>
        </mat-select>
      </div>
    <ul class="hidden sm:flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400">
        <li class="mr-2">
            <a [routerLink]="['/settings', 'general']" routerLinkActive="text-primary-700 border-b-2 border-primary-700"
            class="inline-block p-4  hover:text-primary-700 hover:border-b-2 hover:border-primary-700">
                General
            </a>
        </li>
        <li class="mr-2">
            <a [routerLink]="['/settings', 'alerts']" routerLinkActive="text-primary-700 border-b-2 border-primary-700"
            class="inline-block p-4  hover:text-primary-700 hover:border-b-2 hover:border-primary-700">
                Alerts
            </a>
        </li>
        <li class="mr-2">
            <a [routerLink]="['/settings', 'documents']" routerLinkActive="text-primary-700 border-b-2 border-primary-700"
            class="inline-block p-4  hover:text-primary-700 hover:border-b-2 hover:border-primary-700">
                Documents
            </a>
        </li>
        <li class="mr-2">
            <a [routerLink]="['/settings', 'memberships']" routerLinkActive="text-primary-700 border-b-2 border-primary-700"
            class="inline-block p-4  hover:text-primary-700 hover:border-b-2 hover:border-primary-700">
                Memberships
            </a>
        </li>
    </ul>
    <router-outlet></router-outlet>
</div>