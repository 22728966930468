<div>
    <app-back-button></app-back-button>
</div>
<ng-container *ngIf="loaded">
    <div class="mt-2 flex flex-col">
        <div class="flex justify-between">
            <h1 *ngIf="!editing" class="text-3xl font-medium text-gray-900">Add Membership</h1>
            <h1 *ngIf="editing" class="text-3xl font-medium text-gray-900">Update membership: {{membership?.name}}</h1>
        </div>
    </div>
</ng-container>
<app-loader *ngIf="!loaded" class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"></app-loader>

<app-membership-create-form [membership]="membership" *ngIf="loaded"></app-membership-create-form>
