<div class="bg-primary-50 rounded-lg border border-primary-300 w-full py-2 px-8 text-sm">
    <div class="text-gray-900 font-semibold mb-2">
        {{heading}}
    </div>
    <div class="flex justify-between text-gray-600 font-semibold">
        <span>Total charged</span>
        <span>{{totalCharged|currency}}</span>
    </div>
    <div class="flex justify-between text-gray-500 text-xs mb-2">
        <span>(Tax amount)</span>
        <span>{{totalChargedTax|currency}}</span>
    </div>
    <div class="flex justify-between text-gray-600 font-semibold">
        <span>Your earnings</span>
        <span>{{earnings|currency}}</span>
    </div>
    <div class="flex justify-between text-gray-500 text-xs">
        <span>(Tax amount)</span>
        <span>{{earningsTax|currency}}</span>
    </div>
</div>