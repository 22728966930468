import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DisciplineGrouped, DisciplineGroupedGrade } from 'src/app/models/discipline.model';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-student-training-create-modal',
  templateUrl: './student-training-create-modal.component.html',
  styleUrls: ['./student-training-create-modal.component.scss']
})
export class StudentTrainingCreateModalComponent {
  form: FormGroup;
  submitted = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { disciplines: DisciplineGrouped[] },
    public dialogRef: MatDialogRef<StudentTrainingCreateModalComponent>,
    private datePipe: DatePipe,
    private fb: FormBuilder
  ) {
    this.form = this.fb.group({
      discipline: [null, Validators.required],
      grade: [null, Validators.required],
      manageGrade: [true],
      gradeDate: [this.datePipe.transform(new Date(), 'yyyy-MM-dd'), Validators.required]
    });
  }

  dateChange(date: MatDatepickerInputEvent<Date>) {
    if (date?.value) {
      const timezoneOffsetMinutes = date.value.getTimezoneOffset();
      const adjustedDate = new Date(date.value.getTime() - (timezoneOffsetMinutes * 60 * 1000));
      const localISOString = adjustedDate.toISOString();
      this.form.get('gradeDate')?.setValue(localISOString.split('T')[0]);
    }
  }

  closeDialog(){
    this.dialogRef.close(null);
  }

  confirmDialog(){
    this.submitted = true;
    if (this.form.valid) {
      const formData = this.form.value;
      this.dialogRef.close(formData)
    }
  }
}
