import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DisciplineGrouped } from 'src/app/models/discipline.model';
import { DisciplineService } from 'src/app/services/discipline.service';
import { StudentGrade } from 'src/app/models/student-grade.model';
import { StudentService } from 'src/app/services/student.service';
import { forkJoin } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { StudentTrainingCreateModalComponent } from 'src/app/components/modals/student-training-create-modal/student-training-create-modal.component';

@Component({
  selector: 'app-student-training',
  templateUrl: './student-training.component.html',
  styleUrls: ['./student-training.component.scss']
})
export class StudentTrainingComponent implements OnInit {
  canAddDisciplines: boolean = false;
  loaded: boolean = false;
  disciplines: DisciplineGrouped[] = [];
  disciplinesAvailable: DisciplineGrouped[] = [];
  studentGrades: StudentGrade[] = [];

  constructor(private route: ActivatedRoute, private disciplineService: DisciplineService, private studentService: StudentService, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.loadData()
  }

  loadData(){
    this.loaded = false;
    const studentId = this.route.parent?.snapshot.params['id'];
    forkJoin([
      this.disciplineService.getDisciplineGrades(),
      this.studentService.getStudentGrades(studentId),
    ]).subscribe(([disciplines, studentGrades]) => {
      this.disciplines = disciplines;
      // remove disciplines student has already
      this.disciplinesAvailable = disciplines.filter(d => !studentGrades.map(g => g.disciplineId).includes(d.id));
      this.canAddDisciplines = this.disciplinesAvailable.length > 0;
      this.studentGrades = studentGrades;
      this.loaded = true;
    });
  }

  getGradesByDiscipline(disciplineId: number) {
    const discipline = this.disciplines.find(d => d.id === disciplineId);
    return discipline ? discipline.grades : [];
  }

  addTraining() {
    const studentId = this.route.parent?.snapshot.params['id'];
    const dialogRef = this.dialog.open(StudentTrainingCreateModalComponent, {
      width: '400px',
      data: { disciplines: this.disciplinesAvailable }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.studentService.createStudentGrade(studentId, { gradeId: result.grade.gradeId, manageGrade: result.manageGrade, date: result.gradeDate })
        .subscribe(() => {
          this.loadData()
        });
      }
    });
  }


}
