import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-settings-index',
  templateUrl: './settings-index.component.html',
  styleUrls: ['./settings-index.component.scss']
})
export class SettingsIndexComponent implements OnInit {
  currentRoute: any | undefined = undefined;
  routes: any[] = [
    { title: 'General', route: '/settings'},
    { title: 'Alerts', route: '/settings/alerts'},
    { title: 'Documents', route: '/settings/documents'},
    { title: 'Memberships', route: '/settings/memberships'},
  ]

  constructor(private route: ActivatedRoute, private router: Router, private ref: ChangeDetectorRef) {}

  ngOnInit(): void {
      this.currentRoute = this.routes.find(x => x.title.toLowerCase() == this.route.firstChild?.snapshot.url[0].path);
      this.ref.detectChanges();
  }

  routeChange(selectionChange: MatSelectChange) {
    this.router.navigateByUrl(selectionChange.value.route)
  }
}
