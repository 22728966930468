import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { StudentMembershipCreateModalComponent } from 'src/app/components/modals/student-membership-create-modal/student-membership-create-modal.component';
import { Membership } from 'src/app/models/membership.model';
import { StudentMembershipGrid } from 'src/app/models/student-membership.model';
import { ToasterType } from 'src/app/models/toaster.model';
import { MembershipService } from 'src/app/services/membership.service';
import { StudentService } from 'src/app/services/student.service';
import { ToasterService } from 'src/app/services/toaster.service';

@Component({
  selector: 'app-student-membership',
  templateUrl: './student-membership.component.html',
  styleUrls: ['./student-membership.component.scss']
})
export class StudentMembershipComponent implements OnInit {

  studentMemberships: StudentMembershipGrid[] = [];
  activeMemberships: StudentMembershipGrid[] = [];
  inactiveMemberships: StudentMembershipGrid[] = [];
  memberships: Membership[] = [];
  studentId: number | undefined;
  loading = false;

  constructor(private studentService: StudentService, private membershipService: MembershipService, private route: ActivatedRoute, private dialog: MatDialog, private toasterService: ToasterService, private router: Router) {}

  ngOnInit(): void {
    this.loading = true;
    this.studentId = this.route.parent?.snapshot.params['id'];
    forkJoin({
      studentMemberships: this.studentService.getStudentMemberships(this.studentId!),
      memberships: this.membershipService.getMemberships()
    })
    .subscribe(result => {
      this.studentMemberships = result.studentMemberships;
      this.activeMemberships = this.studentMemberships.filter(x => x.status != 3);
      this.inactiveMemberships = this.studentMemberships.filter(x => x.status == 3);
      this.memberships = result.memberships.filter(x => !x.isArchived && !this.activeMemberships.some(y => y.membershipId == x.id));
      this.loading = false;
    });
  }

  addMembership(): void {
    const dialogRef = this.dialog.open(StudentMembershipCreateModalComponent, {
      width: '400px',
      data: {memberships: this.memberships}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loading = true;
        this.studentService.createStudentMembership(this.studentId!, result.membershipId).subscribe(() => {
          this.toasterService.addToaster({type: ToasterType.Success, message: 'Membership invitation sent'});
          this.ngOnInit()
        });
      }
    });
  }

  gotoStudentMembership(id: number) {
    this.router.navigateByUrl(`/student/${this.studentId}/membership/${id}`);
  }

}
