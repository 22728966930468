<div class="w-full">
    <div class="w-full mt-2 flex justify-between">
        <div>
            <h1 class="text-3xl font-medium text-gray-900">Your students</h1>
            <h4 class="text-gray-500 mt-2">
                View your gym's students - both guests and members.
            </h4>
        </div>
        <div routerLink="/student/create"
            class="h-[30px] sm:h-[38px] select-none hover:cursor-pointer text-white border border-primary-600 bg-primary-600 hover:bg-primary-700 focus:ring focus:ring-primary-100 focus:outline-0 font-medium rounded-lg text-sm pl-2 pr-4 py-2.5 flex items-center">
            <plus-small-outline-icon svgClass="heroicon-sw-1.5" [size]="24"></plus-small-outline-icon>
            <span class="hidden sm:flex">
                Add student
            </span>
            <span class="flex sm:hidden">
                Add
            </span>
        </div>
    </div>
    <div class="flex gap-2 w-full justify-between items-end mt-6">
        <div class="w-[512px]">
            <label for="table-search" class="sr-only">
                Search
            </label>
            <div class="relative mt-1">
                <input type="text" id="table-search"
                    [(ngModel)]="search"
                    class="w-full border border-gray-300 focus:border-primary-300 bg-white focus:outline-0 focus:ring focus:ring-primary-100 pl-10 pr-4 py-3 rounded-lg hover:bg-gray-50"
                    placeholder="Search for a student" (ngModelChange)="studentSearch.next($event)" />
                <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg class="w-5 h-5 text-gray-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd"
                            d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                            clipRule="evenodd"></path>
                    </svg>
                </div>
            </div>
        </div> 
        <div class="flex gap-2 items-center">
            <input type="checkbox" [(ngModel)]="includeArchived" (ngModelChange)="filterStudents()" id="includeArchived"
                class="w-4 h-4 text-gray-50 border-gray-300 rounded focus:ring-0 checked:border-primary-600 checked:text-purple-600" />
            <label for="includeArchived" class="text-gray-700 font-medium text-sm">
                Include archived?
            </label>
        </div>
    </div>
    <div class="mt-6 border overflow-y-hidden rounded-lg">
        <div class="w-full flex py-2.5 px-6 self-stretch items-stretch">
            <div class="text-lg font-medium">Students</div>
        </div>
        <table class="w-full">
            <thead class="bg-gray-50 text-gray-500 text-xs border-y select-none">
                <tr class="text-left">
                    <th scope="col" class="pl-6 py-3 font-medium">
                        <span class="cursor-pointer">Name</span>
                    </th>
                    <th scope="col" class="pl-6 py-3 font-medium">
                        Mobile
                    </th>
                    <th scope="col" class="pl-6 py-3 font-medium">
                        Disciplines
                    </th>
                    <th scope="col" class="pl-6 py-3 font-medium">
                        Membership
                    </th>
                    <th scope="col" class="pl-6 py-3 font-medium">
                        Status
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let student of filteredStudents; let first = first" 
                    [routerLink]="['/student', student.id, 'general']"
                    [class.border-t]="!first" class="hover:bg-gray-50 cursor-pointer" [class.bg-gray-100]="!student.isActive" [class.bg-white]="student.isActive">
                    <td class="px-6 py-4">
                        <div class="flex items-center gap-3">
                            <div>
                                <app-avatar [imageUrl]="student.imageUrl" initials="{{student.firstName.charAt(0).toUpperCase()}}{{student.lastName.charAt(0).toUpperCase()}}"></app-avatar>
                            </div>
                            <div class="flex flex-col">
                                <span class="text-sm font-medium text-gray-900">
                                    {{ student.firstName }} {{ student.lastName }} <span *ngIf="!student.isActive">(Archived)</span>
                                </span>
                                <span class="text-sm text-gray-500">{{ student.email ? student.email : 'No email' }}</span>
                            </div>
                        </div>
                    </td>
                    <td class="px-6 py-4">
                        <div class="text-sm text-gray-500">
                            <a *ngIf="student.mobile" [href]="'tel:' + student.mobile">{{ student.mobile }}</a>
                            <span *ngIf="!student.mobile">-</span>
                        </div>
                    </td>
                    <td class="px-6 py-4">
                        <div class="flex flex-col justify-center">
                            <div>
                                <ng-container *ngIf="student.disciplines.length > 0">
                                    <span *ngFor="let discipline of student.disciplines" class="whitespace-nowrap grow-0 px-2 py-1 text-gray-700 font-medium text-xs rounded-2xl bg-gray-50">
                                        {{discipline.discipline}}: {{discipline.grade}}
                                    </span>
                                </ng-container>
                                <p class="font-medium text-gray-700 text-sm" *ngIf="student.disciplines.length == 0">No disciplines</p>
                            </div>
                        </div>
                    </td>
                    <td class="px-6 py-4">
                        <p class="font-medium text-gray-700 text-sm" *ngIf="student.memberships.length > 0">{{student.memberships.join(', ')}}</p>
                    </td>
                    <td class="px-6 py-4">
                        <div class="flex">
                            <div *ngIf="student.membershipStatus == 1 " class="py-1 px-2 bg-gray-100 rounded-2xl">
                                <div class="text-center text-gray-700 text-[12px] font-medium leading-none">Pending</div>
                            </div>
                            <div *ngIf="student.membershipStatus == 2" class="py-1 px-2 bg-success-100 rounded-2xl">
                                <div class="text-center text-success-700 text-[12px] font-medium leading-none">Active</div>
                            </div>
                            <div *ngIf="student.membershipStatus == 3" class="py-1 px-2 bg-error-100 rounded-2xl ">
                                <div class="text-center text-error-700 text-[12px] font-medium leading-none">Inactive</div>
                            </div>
                            <div *ngIf="student.membershipStatus == 4" class="py-1 px-2 bg-error-100 rounded-2xl">
                                <div class="text-center text-error-700 text-[12px] font-medium leading-none">Cancelling: {{student.membershipCancellationDate | date:'dd-MM-yyyy'}}</div>
                            </div>
                            <div *ngIf="!student.membershipStatus" class="py-1 px-2 bg-gray-100 rounded-2xl">
                                <div class="text-center text-gray-700 text-[12px] font-medium leading-none">Guest</div>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>