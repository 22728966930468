<form [formGroup]="sessionCreateForm" (ngSubmit)="onSubmit()">
    <div class="mb-16">
        <ng-container *ngIf="!editing">
            <div class="mt-8">
                <label class="text-sm font-medium text-gray-700">Choose session type</label>
            </div>
            <div class="flex gap-5 mt-2 items-center pb-5 border-b">
                <button type="button" (click)="setPrivate(true)" [ngClass]="{'rounded-lg border px-3.5 py-2': true, 'bg-primary-600 text-white border-primary-600 hover:bg-primary-700 focus:ring focus:ring-primary-100 focus:outline-0': isPrivate, 'border-gray-300 hover:bg-gray-50 focus:ring focus:ring-gray-100 focus:outline-0': !isPrivate}">Private session</button>
                <span class="text-gray-700 text-sm font-semibold">or</span>
                <button type="button" (click)="setPrivate(false)" [ngClass]="{'rounded-lg border px-3.5 py-2': true, 'bg-primary-600 text-white border-primary-600 hover:bg-primary-700 focus:ring focus:ring-primary-100 focus:outline-0': !isPrivate, 'border-gray-300 hover:bg-gray-50 focus:ring focus:ring-gray-100 focus:outline-0': isPrivate}">Group session</button>
            </div>
        </ng-container>
        <div class="w-full sm:w-1/2 flex flex-col gap-1.5 mt-5">
            <label class="text-sm font-medium text-gray-700">Discipline</label>
            <mat-select [hideSingleSelectionIndicator]="true" formControlName="disciplineId"  class="border border-gray-300 focus:border-primary-300 focus:outline-0 focus:ring focus:ring-primary-100 text-sm pr-4 py-[10px] rounded-lg w-full hover:bg-gray-50">
                <mat-select-trigger>
                <span class="pl-4">
                    {{discipline}}
                </span>
                </mat-select-trigger>
                <mat-option *ngFor="let discipline of disciplines" [value]="discipline.id" class="-ml-2">{{discipline.name}}</mat-option>
            </mat-select>
        </div>
        <ng-container *ngIf="!isPrivate">
            <div class="w-full sm:w-1/2 flex flex-col gap-1.5 mt-5">
                <label class="text-sm font-medium text-gray-700">Title</label>
                <input type="text" formControlName="title" placeholder="Enter a title..." name="title" [ngClass]="{'border focus:outline-0 focus:ring text-sm px-4 py-3 rounded-lg w-full hover:bg-gray-50': true, 'border-error-300 focus:border-error-300 focus:ring-error-300': submitted && !sessionCreateForm.get('title')?.valid ?? false, 'border-gray-300 focus:border-primary-300 focus:ring-gray-100': !submitted || sessionCreateForm.get('title')?.valid ?? true}" />
                <span *ngIf="submitted && !sessionCreateForm.get('title')?.valid ?? false"  class="text-error-500">Title is required</span>
            </div>
            <div class="w-full sm:w-1/2 flex flex-col gap-1.5 mt-5 pb-5">
                <label class="text-sm font-medium text-gray-700">Description</label>
                <textarea rows="5" formControlName="description" placeholder="Enter a description..." name="description" class="border border-gray-300 focus:border-primary-300 focus:outline-0 focus:ring focus:ring-primary-100 text-sm px-4 py-3 rounded-lg w-full hover:bg-gray-50"></textarea>
            </div>
            <div class="w-full border-b"></div>
            <ng-container *ngIf="!editing">
                <div class="mt-5">
                    <label class="text-sm font-medium text-gray-700">Is this a once off?</label>
                </div>
                <div class="flex gap-5 mt-2 items-center pb-5 border-b">
                    <button type="button" (click)="setType(1)" [ngClass]="{'rounded-lg border px-3.5 py-2': true, 'bg-primary-600 text-white border-primary-600 hover:bg-primary-700 focus:ring focus:ring-primary-100 focus:outline-0': type == 1, 'border-gray-300 hover:bg-gray-50 focus:ring focus:ring-gray-100 focus:outline-0': type == 2}">Single session</button>
                    <span class="text-gray-700 text-sm font-semibold">or</span>
                    <button type="button" (click)="setType(2)" [ngClass]="{'rounded-lg border px-3.5 py-2': true, 'bg-primary-600 text-white border-primary-600 hover:bg-primary-700 focus:ring focus:ring-primary-100 focus:outline-0': type == 2, 'border-gray-300 hover:bg-gray-50 focus:ring focus:ring-gray-100 focus:outline-0': type == 1}">Recurring session</button>
                </div>
            </ng-container>
        </ng-container>
        <div class="flex flex-col sm:flex-row mt-5 gap-5" [class.mb-12]="!isPrivate && type == 1">
            <div class="flex flex-col gap-1.5">
                <label class="text-sm font-medium text-gray-700">Date</label>
                <input [value]="defaultDate" (click)="date.open()" [matDatepicker]="date" (dateChange)="dateChange($event)"  class="border border-gray-300 focus:border-primary-300 focus:outline-0 focus:ring focus:ring-primary-100 text-sm px-4 py-3 rounded-lg w-full sm:w-36 hover:bg-gray-50"/>
                <calendar-outline-icon (click)="date.open()" class="absolute mt-9 right-10 sm:right-auto sm:ml-28 w-6 text-gray-500"></calendar-outline-icon>
                <mat-datepicker  touchUi #date></mat-datepicker>
            </div>
            <div class="flex flex-col gap-1.5">
                <label class="text-sm font-medium text-gray-700">Start</label>
                <input [value]="defaultStartTime" [ngxTimepicker]="startTime" class="border border-gray-300 focus:border-primary-300 focus:outline-0 focus:ring focus:ring-primary-100 text-sm px-4 py-3 rounded-lg w-full sm:w-32 hover:bg-gray-50">
                <ngx-material-timepicker [theme]="timePickerTheme" (timeChanged)="timeChanged('startTime', $event)" #startTime></ngx-material-timepicker>
            </div>
            <div class="flex flex-col gap-1.5">
                <label class="text-sm font-medium text-gray-700">Finish</label>
                <input [value]="defaultEndTime" [ngxTimepicker]="endTime"  class="border border-gray-300 focus:border-primary-300 focus:outline-0 focus:ring focus:ring-primary-100 text-sm px-4 py-3 rounded-lg w-full sm:w-32 hover:bg-gray-50">
                <ngx-material-timepicker [theme]="timePickerTheme" (timeChanged)="timeChanged('endTime', $event)" #endTime></ngx-material-timepicker>
            </div>
        </div>
        <div *ngIf="!isPrivate && type == 2 && !editing" class="flex flex-col sm:flex mt-5 gap-5">
            <div class="flex flex-col gap-1.5 w-full sm:w-40">
                <label class="text-sm font-medium text-gray-700">Frequency</label>
                <mat-select [hideSingleSelectionIndicator]="true" formControlName="frequency" class="border border-gray-300 focus:border-primary-300 focus:outline-0 focus:ring focus:ring-primary-100 text-sm py-[10px] pr-4 rounded-lg w-full hover:bg-gray-50" (change)="frequencyChange()">
                    <mat-select-trigger>
                    <span class="pl-4 mr-1">
                        {{frequency}}
                    </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let frequency of frequencies" [value]="frequency.id" class="-ml-2">{{frequency.name}}</mat-option>
                </mat-select>
            </div>
            <div class="flex flex-col gap-1.5">
                <label class="text-sm font-medium text-gray-700">Number of sessions to create</label>
                <input formControlName="occurances" defaultValue="1" type="number" min="0" [max]="frequency == 'Weekly' ? 52 : 26" name="occurances" [ngClass]="{'border sm:w-20 focus:outline-0 focus:ring text-sm px-4 py-3 rounded-lg w-full hover:bg-gray-50': true, 'border-error-300 focus:border-error-300 focus:ring-error-300': submitted && !sessionCreateForm.get('occurances')?.valid ?? false, 'border-gray-300 focus:border-primary-300 focus:ring-gray-100': !submitted || sessionCreateForm.get('occurances')?.valid ?? true}" />
                <span [ngClass]="{'text-sm': true, 'text-gray-500': !submitted || sessionCreateForm.get('occurances')?.valid ?? true, 'text-error-500': submitted && !sessionCreateForm.get('occurances')?.valid ?? false}" class="text-gray-500 text-sm">Limited to 1 year of future sessions</span>
            </div>
        </div>
        <div *ngIf="isPrivate">
            <div class="w-full sm:w-1/2 flex flex-col gap-1.5 mt-5">
                <label class="text-sm font-medium text-gray-700">Add student</label>
                <input type="text" class="w-full border border-gray-300 focus:border-primary-300 focus:outline-0 focus:ring focus:ring-primary-100 text-sm pr-4 py-[12px] rounded-lg hover:bg-gray-50" placeholder="Search for a student..." matInput [formControl]="autoCompleteControl" [matAutocomplete]="auto"/>
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="optionSelected($event)">
                    <mat-option *ngFor="let student of filteredStudents | async" [value]="student">
                        {{student.firstName}} {{student.lastName}}
                    </mat-option>
                </mat-autocomplete>
            </div>
            <div class="mt-5 border overflow-hidden rounded-lg">
                <table class="w-full">
                    <thead class="bg-gray-50 text-gray-500">
                        <tr class="text-left">
                            <th class="pl-6 py-3 w-1/4 font-medium">
                                Name
                            </th>
                            <th class="pl-6 py-3 font-medium">
                                Disciplines
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody class="[&>*:not(:first-child)]:border-t">
                        <tr *ngFor="let student of selectedStudents" class="hover:bg-gray-50">
                            <td class="px-6 py-4 cursor-pointer" >
                                <div class="flex items-center gap-3">
                                    <div>
                                        <app-avatar [initials]="student.firstName.charAt(0).toUpperCase() + student.lastName.charAt(0).toUpperCase()"></app-avatar>
                                    </div>
                                    <div class="flex flex-col">
                                        <span class="font-medium">{{student.firstName}} {{student.lastName}} <span *ngIf="!student.isActive">(Archived)</span></span>
                                        <span class="text-gray-500">{{student.email}}</span>
                                    </div>
                                </div>
                            </td>
                            <td class="px-6 py-4">
                                <div class="flex flex-col justify-center">
                                    <div>
                                        <span *ngFor="let discipline of student.disciplines" class="grow-0 px-2 py-1 text-gray-700 font-medium text-xs rounded-2xl bg-gray-50">
                                        {{discipline.discipline}}: {{discipline.grade}}
                                        </span>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="flex justify-end">
                                    <trash-outline-icon (click)="removeStudent(student.id)"  class="w-6 h-5 mr-7 cursor-pointer"></trash-outline-icon>
                                </div>
                            </td>
                        </tr>
                        <tr *ngIf="selectedStudents.length === 0">
                            <td class="px-6 py-4" [class.text-gray-500]="!privateStudentError" [class.text-error-500]="privateStudentError" colSpan="3">
                                You need to add in a student...
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <app-save-footer *ngIf="sessionCreateForm.dirty" [buttonText]="editing ? 'Update session' : 'Create session'" [error]="submitted && !sessionCreateForm.valid"></app-save-footer>
</form>