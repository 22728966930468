<form [formGroup]="documentCreateForm" (ngSubmit)="onSubmit()">
    <div class="mt-8 mb-8">
        <div class="flex flex-col gap-5 items-start">
            <div class="w-1/2">
                <label class="block mb-2 text-sm font-medium text-gray-700">Document name</label>
                <input type="text" formControlName="name"
                    [ngClass]="{'bg-gray-50 text-gray-600' : editing, 'w-full border focus:outline-0 focus:ring px-4 py-3 rounded-lg hover:bg-gray-50': true, 'border-error-300 focus:border-error-300 focus:ring-error-300': submitted && !documentCreateForm.valid && !documentCreateForm.get('name')?.valid ?? false, 'border-gray-300 focus:border-primary-300 focus:ring-gray-100': !submitted || documentCreateForm.valid || documentCreateForm.get('name')?.valid ?? true}"
                    placeholder="Required..." />
                <span *ngIf="submitted && !documentCreateForm.valid && !documentCreateForm.get('name')?.valid ?? false"  class="block mt-2 text-error-500">Document name is required</span>
            </div>
            <div class="w-1/2">
                <label class="block mb-2 text-sm font-medium text-gray-700">Describe your document</label>
                <textarea type="text" rows="3" formControlName="description"
                    [ngClass]="{'resize-none w-full border focus:outline-0 focus:ring px-4 py-3 rounded-lg hover:bg-gray-50': true, 'border-error-300 focus:border-error-300 focus:ring-error-300': submitted && !documentCreateForm.get('description')?.valid ?? false, 'border-gray-300 focus:border-primary-300 focus:ring-gray-100': !submitted || documentCreateForm.get('description')?.valid ?? true}"
                    placeholder="Enter a description..."></textarea>
                <span *ngIf="submitted && !documentCreateForm.get('description')?.valid ?? false"  class="block mt-2 text-error-500">Document description is required</span>
            </div>
            <div class="w-1/2">
                <div class="flex gap-2 items-center">
                    <mat-slide-toggle formControlName="signatureRequired"
                        class="relative inline-flex items-center rounded-full transition-colors"
                        [color]="'primary'"
                        [disableRipple]="true">
                      </mat-slide-toggle>
                      <mat-label class="text-sm font-medium">Require student signature?</mat-label>
                </div>
            </div>
            <div class="w-full h-full py-2.5 border-t border-gray-200">
                <label class="block mb-2 text-sm font-medium text-gray-700">Provide your document details</label>
                <app-editor-wrapper formControlName="content" (initialValueSet)="onInitialValueSet()"></app-editor-wrapper>
                <span *ngIf="submitted && !documentCreateForm.get('content')?.valid ?? false"  class="block mt-2 text-error-500">Document details is required</span>
            </div>
        </div>
    </div>
    <app-save-footer *ngIf="this.documentCreateForm.dirty" [buttonText]="editing ? 'Update document' : 'Create document'" [error]="submitted && !documentCreateForm.valid"></app-save-footer>
</form>

