import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StudentSearch } from 'src/app/models/student-search.model';

@Component({
  selector: 'app-note-create-modal',
  templateUrl: './note-create-modal.component.html',
  styleUrls: ['./note-create-modal.component.scss']
})
export class NoteCreateModalComponent {
  selectedStudent: StudentSearch | undefined;
  content: string | undefined;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { students: StudentSearch[] }) { 
    if (data.students && data.students.length > 0) {
      this.selectedStudent = data.students[0];
    }
  }
}
