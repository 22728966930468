import { Component, OnInit } from '@angular/core';
import { StudentNote } from 'src/app/models/session-instance.model';
import { StudentService } from 'src/app/services/student.service';
import * as moment from 'moment-timezone';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { NoteCreateModalComponent } from 'src/app/components/modals/note-create-modal/note-create-modal.component';
import { Student } from 'src/app/models/student.model';
import { forkJoin } from 'rxjs';
import { NoteService } from 'src/app/services/note.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { ToasterType } from 'src/app/models/toaster.model';


const defaultPageSize: number = 5;

@Component({
  selector: 'app-student-notes',
  templateUrl: './student-notes.component.html',
  styleUrls: ['./student-notes.component.scss']
})
export class StudentNotesComponent implements OnInit {
  studentId!: number;
  student!: Student;
  currentPage = 1;
  lastPage = 1;
  pageSize: number = defaultPageSize;
  loaded: boolean = false;
  studentNotes: StudentNote[] = [];
  currentPageNotes: StudentNote[] = [];
  constructor(private route: ActivatedRoute, private studentService: StudentService, private dialog: MatDialog, private noteService: NoteService, private toasterService: ToasterService){
  }

  ngOnInit(): void {
    const id = this.route.parent?.snapshot.params['id'];
    if (id) {
      this.studentId = +id;
      const notes = this.studentService.getStudentNotes(+id);
      const student = this.studentService.getStudent(+id);;
      forkJoin([notes, student])
      .subscribe(results => {
        this.studentNotes = results[0];
        this.student = results[1];
        this.#updateCurrentPageStudents();
        this.loaded = true;
      })
    }
  }

  addNote(): void {
    const dialogRef = this.dialog.open(NoteCreateModalComponent, {
      width: '400px',
      data: {students: [this.student]}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.noteService.createNote({ studentId: this.studentId, content: result.content })
        .subscribe((note) => {
          this.studentNotes.unshift(note);
          this.currentPage = 1;
          this.#updateCurrentPageStudents();
          this.toasterService.addToaster({type: ToasterType.Success, message: 'Note created'});
        });
      }
    });
  }

  getNoteDate(date: string) {
    return moment(date).format('dddd DD MMM');
  }

  getNoteDateElapsed(date: string) {
    return moment.tz(date, 'UTC').fromNow();
  }

  canGoNext() {
    return this.currentPage * this.pageSize < this.studentNotes.length;
  }

  next() {
    this.currentPage++;
    this.#updateCurrentPageStudents();
  }

  canGoBack() {
    return this.currentPage > 1;
  }

  back() {
    this.currentPage--;
    this.#updateCurrentPageStudents();
  }

  #updateCurrentPageStudents() {
    const size = this.pageSize
    const start = this.currentPage == 1 ? 0 : (this.currentPage - 1) * size;
    const end = this.currentPage == 1 ? size : (this.currentPage - 1) * size + size;
    this.currentPageNotes = this.studentNotes.slice(start, end);
    this.lastPage = Math.ceil(this.studentNotes.length / size);
  }
}
