
<div *ngIf="loaded" class="flex items-center justify-end align-end gap-3 top-0 right-8 absolute pt-16 sm:pt-8">
    <div (click)="archive()"
        class="h-[30px] sm:h-[38px] select-none hover:cursor-pointer text-gray-500 border border-gray-300 hover:bg-gray-50 focus:ring focus:ring-gray-100 focus:outline-0 font-medium rounded-lg text-sm pl-2 pr-4 py-2.5 flex items-center space-x-2">
        <archive-box-outline-icon svgClass="heroicon-sw-1.5" [size]="20"></archive-box-outline-icon>
        <span>
            {{student.isActive ? 'Archive' : 'Unarchive'}}
        </span>
    </div>
</div>
<div class="realtive mb-4">
    <app-loader *ngIf="!loaded" class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"></app-loader>
    <app-student-create-form *ngIf="loaded" [student]="student"></app-student-create-form>
</div>