import { Component } from '@angular/core';
import { GymService } from 'src/app/services/gym.service';

@Component({
  selector: 'app-settings-alerts',
  templateUrl: './settings-alerts.component.html',
  styleUrls: ['./settings-alerts.component.scss']
})
export class SettingsAlertsComponent {
  gym: any;
  loaded: boolean = false;

  constructor(private gymService: GymService) {}

  ngOnInit(): void {
    this.gymService.getGymAlerts().subscribe(response => {
      this.gym = response;
      this.loaded = true;
    })
  }
}

