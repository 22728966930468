import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { StudentService } from 'src/app/services/student.service';

@Component({
  selector: 'app-student-id',
  templateUrl: './student-id.component.html',
  styleUrls: ['./student-id.component.scss']
})
export class StudentIdComponent implements OnInit {
  loading = true;
  student: any;
  currentRoute: any | undefined = undefined;
  routes: any[] = [
    { title: 'General', route: '/student/:id/general'},
    { title: 'Training', route: '/student/:id/training'},
    { title: 'Notes', route: '/student/:id/notes'},
    { title: 'History', route: '/student/:id/history'},
    { title: 'Membership', route: '/student/:id/membership'},
    { title: 'Documents', route: '/student/:id/documents'}
  ]

  constructor(private route: ActivatedRoute, private studentService: StudentService, private router: Router, private ref: ChangeDetectorRef) {}

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.studentService.getStudent(+id).
      subscribe(result => {
        this.student = result;
        this.loading = false;
      });
      this.currentRoute = this.routes.find(x => x.title.toLowerCase() == this.route.firstChild?.snapshot.url[0].path);
      this.ref.detectChanges();
    }
  }

  routeChange(selectionChange: MatSelectChange) {
    this.router.navigateByUrl(selectionChange.value.route.replace(':id', this.student.id))
  }

}
