import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Toaster, ToasterType } from '../models/toaster.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SuccessToasterComponent } from '../components/toasters/success-toaster/success-toaster.component';
import { ErrorToasterComponent } from '../components/toasters/error-toaster/error-toaster.component';

@Injectable({
  providedIn: 'root'
})
export class ToasterService {
  addToaster(toaster: Toaster) {
    if (toaster.type == ToasterType.Error) {
      this.snackBar.openFromComponent(ErrorToasterComponent, { 
        duration: 5000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
        panelClass: 'error-toaster',
        data: toaster.message
      });
    }
    if (toaster.type == ToasterType.Success) {
      this.snackBar.openFromComponent(SuccessToasterComponent, { 
        duration: 5000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
        panelClass: 'success-toaster',
        data: toaster.message
      });
    }
  }

  constructor(private snackBar: MatSnackBar) { }
}
