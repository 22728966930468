import { Component, EventEmitter, Input, Output } from '@angular/core';
import * as moment from 'moment';
import { AlertNotification, AlertType } from 'src/app/models/alert.model';
require("moment-precise-range-plugin");

@Component({
  selector: 'alert-notification-bar',
  templateUrl: './alert-notification-bar.component.html',
  styleUrls: ['./alert-notification-bar.component.scss']
})
export class AlertNotificationBarComponent {
  alertType = AlertType;
  @Input() alerts: AlertNotification[] = [];
  @Output() onDismiss: EventEmitter<number> = new EventEmitter<number>();
  @Output() onRemind: EventEmitter<number> = new EventEmitter<number>();

  getTime(date: string | undefined) : string {
    const start = moment(date);
    const end = moment(new Date().setDate(new Date().getDate() - 1));
    // @ts-expect-error - moment.preciseDiff cannot be imported in es6, works fine
    const diff = moment.preciseDiff(start, end, true); 

    let returnValue = "";

    if (diff.years > 0) {
        returnValue += diff.years == 1 ? `${diff.years} year ` : `${diff.years} years `;
    }

    if (diff.months > 0) {
        returnValue += diff.months == 1 ? `${diff.months} month ` : `${diff.months} months `;
    }

    if (diff.days > 0) {
        returnValue += diff.days == 1 ? `${diff.days} day ` : `${diff.days} days`;
    }

    return returnValue;
  }
}
