<div class="flex items-center justify-between">
    <app-back-button></app-back-button>
    <button *ngIf="editing" (click)="delete()" type="button" class="h-[30px] flex sm:hidden items-center gap-1 text-warning-700 border border-warning-700 bg-warning-25 hover:bg-warning-100 focus:ring focus:ring-gray-100 focus:outline-0 font-medium rounded-lg text-sm px-3 py-1.5 text-center mr-2">
        <trash-outline-icon svgClass="flex-inline" [size]="16"></trash-outline-icon> Delete
    </button>
</div>
<div class="mt-2 flex flex-col">
    <div class="flex justify-between">
        <h1 *ngIf="!editing" class="text-3xl font-medium text-gray-900">Add session</h1>
        <h1 *ngIf="editing" class="text-3xl font-medium text-gray-900">Edit session: {{sessionInstance?.title}}</h1>
        <button *ngIf="editing" (click)="delete()" type="button" class="hidden h-[38px] sm:flex items-center gap-1 text-warning-700 border border-warning-700 bg-warning-25 hover:bg-warning-100 focus:ring focus:ring-gray-100 focus:outline-0 font-medium rounded-lg text-sm px-3 py-1.5 text-center mr-2">
            <trash-outline-icon svgClass="flex-inline" [size]="16"></trash-outline-icon> Delete
        </button>
    </div>
</div>
<app-loader *ngIf="loading" class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"></app-loader>
<app-session-create-form *ngIf="!loading" [sessionInstanceId]="sessionInstanceId" [sessionInstance]="sessionInstance" [disciplines]="disciplines" [students]="students" [date]="date" [hour]="hour" [minute]="minute"></app-session-create-form>