<app-loader *ngIf="!loaded" class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"></app-loader>
<ng-container *ngIf="loaded">
    <div *ngIf="studentNotes && studentNotes.length == 0" class="mt-8" >
        <!-- replace with an empty section placeholder component? -->
        <p class="">There aren't any notes yet</p>
    </div>
    <div class="flex justify-end align-end gap-3 top-0 right-8 absolute pt-16 sm:pt-6">
        <div class="h-[30px] sm:h-[38px] select-none hover:cursor-pointer text-white border border-primary-600 bg-primary-600 hover:bg-primary-700 focus:ring focus:ring-primary-100 focus:outline-0 font-medium rounded-lg text-sm pl-2 pr-4 py-2.5 flex items-center space-x-2" (click)="addNote()">
            <plus-small-outline-icon svgClass="heroicon-sw-1.5" [size]="24"></plus-small-outline-icon>
            <span class="hidden sm:flex">Add note</span>
            <span class="flex sm:hidden">Add</span>
        </div>
    </div>
    <div *ngIf="studentNotes && studentNotes.length > 0" class="mt-8 border overflow-y-hidden rounded-lg" >
        <table class="w-full">
            <tbody class="[&>*:not(:first-child)]:border-t">
                <tr class="hover:bg-gray-50" *ngFor="let note of currentPageNotes">
                    <td class="px-6 py-4 w-full align-top">
                        <div *ngIf="note.sessionInstanceId" class="text-sm font-medium text-gray-900">
                            {{note.isPrivate ? 'Private session' : 'General session'}}
                            {{!note.sessionInstanceIsActive ? ' (Cancelled)' : ''}}
                        </div>
                        <div *ngIf="!note.sessionInstanceId" class="text-sm font-medium text-gray-900">
                            General note
                        </div>
                        <div class="text-sm text-gray-500">
                            <ng-container *ngFor="let line of note.content.split('\n'); let i = index">
                                <p [class]="i === 0 ? '':'mt-1'">{{ line }}</p>
                            </ng-container>
                        </div>
                    </td>
                    <td class="px-6 py-4 w-auto align-top">
                        <div class="flex flex-col">
                            <span class="text-sm font-medium text-gray-900 whitespace-nowrap">
                                {{getNoteDate(note.date)}}
                            </span>
                            <span class="text-sm text-gray-500 whitespace-nowrap">
                                {{getNoteDateElapsed(note.date)}}
                            </span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="px-6 py-4">
                        <span>Page {{currentPage}} of {{lastPage}}</span>
                    </td>
                    <td class="px-4 py-4" colSpan="2">
                        <div *ngIf="studentNotes.length > pageSize" class="flex justify-end text-sm text-gray-500 font-medium">
                            <button (click)="back()" [disabled]="!canGoBack()"
                                class="flex items-center gap-1 text-gray-700 border border-gray-300 hover:bg-gray-50 focus:ring focus:ring-gray-100 focus:outline-0 font-medium rounded-lg text-sm px-3 py-1.5 text-center mr-2 disabled:bg-gray-100">
                                Previous
                            </button>
                            <button (click)="next()" [disabled]="!canGoNext()"
                                class="flex items-center gap-1 text-gray-700 border border-gray-300 hover:bg-gray-50 focus:ring focus:ring-gray-100 focus:outline-0 font-medium rounded-lg text-sm px-3 py-1.5 text-center disabled:bg-gray-100">
                                Next
                            </button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</ng-container>