import { Injectable } from '@angular/core';
import { BehaviorSubject, debounceTime, fromEvent } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WindowService {
  isMobile: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor() {
    this.isMobile.next(window.innerWidth < 800);
    fromEvent(window, 'resize')
      .pipe(debounceTime(200)
      ).subscribe((event: any) => this.isMobile.next(event.target.innerWidth < 640));
   }
}
