import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Document } from 'src/app/models/document.model';
import { Membership } from 'src/app/models/membership.model';
import { DocumentService } from 'src/app/services/document.service';
import { MembershipService } from 'src/app/services/membership.service';

@Component({
  selector: 'app-settings-membership-create',
  templateUrl: './settings-membership-create.component.html',
  styleUrls: ['./settings-membership-create.component.scss']
})
export class SettingsMembershipCreateComponent implements OnInit {
  loaded: boolean = false;
  editing: boolean = false;
  membership: Membership | undefined = undefined;
  documentId: number | null = null;

  constructor(private route: ActivatedRoute, private router: Router, private membershipService: MembershipService) {

  }

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');

    if (id) {
      console.log(id);
      this.documentId = +id;
      this.editing = true;
      this.membershipService.getMembership(+id).subscribe(response => {
        this.membership = response;
        this.loaded = true;
      })
    }
    else {
      this.loaded = true;
    }
  }
}
