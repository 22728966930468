import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StripeService {

  constructor(private http: HttpClient) { }

  createAccount() {
    return this.http.get<string>(`${environment.baseUrl}/stripe`, {responseType: 'text' as 'json'});
  }
}
