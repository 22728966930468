import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Discipline, DisciplineGrouped } from '../models/discipline.model';

@Injectable({
  providedIn: 'root'
})
export class DisciplineService {

  constructor(private http: HttpClient) { }

  getDisciplines() {
    return this.http.get<Discipline[]>(`${environment.baseUrl}/discipline`);
  }

  getDisciplineGrades() {
    return this.http.get<DisciplineGrouped[]>(`${environment.baseUrl}/discipline/grades`);
  }
}
