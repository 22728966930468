import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Membership } from '../models/membership.model';

@Injectable({
  providedIn: 'root'
})
export class MembershipService {

  constructor(private http: HttpClient) { }

  createMembership(membership: any) {
    return this.http.post<number>(`${environment.baseUrl}/membership`, membership);
  }

  getMembership(id: number) {
    return this.http.get<Membership>(`${environment.baseUrl}/membership/${id}`)
  }

  getMemberships() {
    return this.http.get<Membership[]>(`${environment.baseUrl}/membership`)
  }

  updateMembership(id: number, membership: any) {
    return this.http.put(`${environment.baseUrl}/membership/${id}`, membership)
  }

  archiveMembership(id: number) {
    return this.http.post(`${environment.baseUrl}/membership/${id}/archive`, {})
  }

  restoreMembership(id: number) {
    return this.http.post(`${environment.baseUrl}/membership/${id}/restore`, {})
  }

  billingCompleted() {
    return this.http.get<boolean>(`${environment.baseUrl}/membership/billing-completed`)
  }
}
