<div class="mt-2">
    <div *ngFor="let month of history">
        <div class="w-full h-6 justify-start items-center gap-2 inline-flex mb-2">
            <div class="grow shrink basis-0 h-px bg-gray-200"></div>
            <div class="text-center text-gray-500 text-base font-bold font-['Inter'] leading-normal">{{month.key}}</div>
            <div class="grow shrink basis-0 h-px bg-gray-200"></div>
        </div>
        <div class="w-full justify-start items-start gap-1.5 inline-flex" *ngFor="let item of month.values; let last = last;">
            <div class="self-stretch pb-1 flex-col justify-start items-center gap-1 inline-flex">
                <div class="w-12 h-12 p-3 bg-white rounded-lg shadow border border-gray-200 justify-center items-center inline-flex">
                    <div class="w-6 h-6 relative flex-col justify-start items-start flex" [ngSwitch]="item.type">
                        <document-check-outline-icon *ngSwitchCase="1"></document-check-outline-icon>
                        <user-circle-outline-icon *ngSwitchCase="2"></user-circle-outline-icon>
                        <trophy-outline-icon *ngSwitchCase="3"></trophy-outline-icon>
                        <currency-dollar-outline-icon *ngSwitchCase="4"></currency-dollar-outline-icon>
                        <stop-circle-outline-icon *ngSwitchCase="5"></stop-circle-outline-icon>
                    </div>
                </div>
            <div *ngIf="!last" class="w-0.5 h-full bg-gray-300 rounded-sm"></div>
            </div>
            <div class="grow shrink basis-0 pl-1.5 hover:bg-purple-100 rounded-lg flex-col justify-start items-start gap-2.5 inline-flex">
            <div class="self-stretch py-1 flex-col justify-start items-start flex">
                <div class="self-stretch text-slate-700 text-sm font-semibold leading-tight">{{item.title}}</div>
                <div class="self-stretch text-slate-600 text-sm font-normal leading-tight">{{item.message}} <a class="text-primary-600" *ngIf="item.url" [routerLink]="item.url">View details.</a></div>
            </div>
            </div>
        </div>
    </div>
</div>