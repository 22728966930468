import { Component } from '@angular/core';

@Component({
  selector: 'app-settings-disciplines',
  templateUrl: './settings-disciplines.component.html',
  styleUrls: ['./settings-disciplines.component.scss']
})
export class SettingsDisciplinesComponent {

}
