<h1 class="text-lg font-semibold text-gray-900 mb-2 pt-6 px-6">
    Add a membership
  </h1>
  <div class="pb-4 text-sm text-gray-500">
      <div *ngIf="data.memberships && data.memberships.length > 0"  class="px-6">
        <mat-select [(ngModel)]="selectedMembership" [hideSingleSelectionIndicator]="true"
          class="border border-gray-300 focus:border-primary-300 focus:outline-0 focus:ring focus:ring-primary-100 text-sm pr-4 py-[10px] rounded-lg w-full hover:bg-gray-50">
          <mat-select-trigger>
            <span class="pl-4">
              {{selectedMembership?.name}}
            </span>
          </mat-select-trigger>
          <mat-option *ngFor="let membership of data?.memberships" [value]="membership"  class="-ml-2">{{membership.name}}</mat-option>
        </mat-select>
      </div>
  </div>
  <div class="flex pt-4 pb-6 px-6 gap-3">
    <button class="grow text-gray-700 border border-gray-300 hover:bg-gray-50 focus:ring focus:ring-gray-100 focus:outline-0 font-medium rounded-lg text-sm px-3 py-1.5 text-center" [mat-dialog-close]="null">Cancel</button>
    <button class="grow bg-primary-600 text-white border border-primary-300 hover:bg-primary-70 focus:ring focus:ring-error-100 focus:outline-0 font-medium rounded-lg text-sm px-3 py-1.5 text-center" [mat-dialog-close]="{membershipId: selectedMembership?.id}">Add membership</button>
  </div>