import { Component, OnInit } from '@angular/core';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { StudentGrid } from 'src/app/models/student-grid.model';
import { StudentService } from 'src/app/services/student.service';

@Component({
  selector: 'app-student-index',
  templateUrl: './student-index.component.html',
  styleUrls: ['./student-index.component.scss']
})
export class StudentIndexComponent implements OnInit {
  search: string = '';
  students: StudentGrid[] = [];
  filteredStudents: StudentGrid[] = [];
  studentSearch = new Subject<string>();
  includeArchived: boolean = false;

  constructor(private studentService: StudentService) {}

  ngOnInit(): void {
    this.studentService.getStudents().subscribe(students => {
      this.students = students;
      this.filteredStudents = students.filter(x => x.isActive);
    });

    this.studentSearch.pipe(
      debounceTime(400))
      .subscribe(value => {
        this.search = value.toLowerCase();
        this.filterStudents();
      });
  }

  filterStudents() {
    this.filteredStudents = this.students.filter(x => (x.firstName.toLowerCase().startsWith(this.search) || x.lastName.toLowerCase().startsWith(this.search)) && (this.includeArchived || (!this.includeArchived && x.isActive)));
  }
}

