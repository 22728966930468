import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToasterType } from 'src/app/models/toaster.model';
import { GymService } from 'src/app/services/gym.service';
import { ToasterService } from 'src/app/services/toaster.service';

@Component({
  selector: 'app-settings-alerts-form',
  templateUrl: './settings-alerts-form.component.html',
  styleUrls: ['./settings-alerts-form.component.scss']
})
export class SettingsAlertsFormComponent {
  @Input() gym: any;

  settingsAlertsForm: FormGroup;
  gradingTabOpen: boolean = true;
  abandonmentTabOpen: boolean = true;

  constructor(private fb: FormBuilder, private gymService: GymService, private toasterService: ToasterService) {
    this.settingsAlertsForm = this.fb.group({
      gradingAlertSettings: this.fb.group({
        notifyMember: [false],
        notifyGuest: [false],
        notifyGradedStudent: [false],
        notifyNonGradedStudent: [false],
        visitsRequired: [60],
        privateWeight: [1]
      }),
      abandonmentAlertSettings: this.fb.group({
        notifyMember: [false],
        notifyGuest: [false],
        notifyGradedStudent: [false],
        notifyNonGradedStudent: [false],
        daysWithoutVisit: [0],
        requiredContact: [1]
      })
    })
  }

  ngOnInit(): void {
    if (this.gym) {
      this.settingsAlertsForm.patchValue(this.gym);
    }
  }

  toggleGradingTab() {
    this.gradingTabOpen = !this.gradingTabOpen;
  }

  toggleAbandonmentTab() {
    this.abandonmentTabOpen = !this.abandonmentTabOpen;
  }

  onSubmit() {
    if (this.settingsAlertsForm.valid) {
      this.gymService.updateGymAlerts(this.settingsAlertsForm.value).subscribe(() => this.toasterService.addToaster({type: ToasterType.Success, message: 'Settings updated'}));
      // hide the save footer
      this.settingsAlertsForm.markAsPristine();
    }
  }
}
