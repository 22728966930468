import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Document } from '../models/document.model';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  constructor(private http: HttpClient) { }

  getDocument(id: number) {
    return this.http.get<Document>(`${environment.baseUrl}/document/${id}`)
  }

  getDocuments() {
    return this.http.get<Document[]>(`${environment.baseUrl}/document`)
  }

  createDocument(document: any) {
    return this.http.post<number>(`${environment.baseUrl}/document`, document);
  }

  updateDocument(id: number, document: any) {
    return this.http.put(`${environment.baseUrl}/document/${id}`, document)
  }

  archiveDocument(id: number) {
    return this.http.post(`${environment.baseUrl}/document/${id}/archive`, {})
  }

  restoreDocument(id: number) {
    return this.http.post(`${environment.baseUrl}/document/${id}/restore`, {})
  }

  downloadDocument(id: number, version: number) {
    return this.http.get(`${environment.baseUrl}/document/${id}/download/${version}`, {
      responseType: 'text',
    });
  }

  sendDocument(studentId: number, documentVersionId: number) {
    return this.http.post(`${environment.baseUrl}/student/${studentId}/document/${documentVersionId}/send`, null, {responseType: 'text'});
  }

  downloadStudentDocument(studentDocumentId: number) {
    return this.http.get(`${environment.baseUrl}/document/student-document/${studentDocumentId}`, {
      responseType: 'text',
    });
  }
}
