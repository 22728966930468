import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { CalendarOptions, DatesSetArg, EventClickArg } from '@fullcalendar/core';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import enAuLocale from '@fullcalendar/core/locales/en-au';
import { SessionService } from 'src/app/services/session.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { MatMenuTrigger } from '@angular/material/menu';
import { WindowService } from 'src/app/services/window.service';
import { FullCalendarComponent } from '@fullcalendar/angular';
import * as moment from 'moment';
import { NavService } from 'src/app/services/nav.service';
import { AlertService } from 'src/app/services/alert.service';
import { AlertNotification } from 'src/app/models/alert.model';
import { ToasterService } from 'src/app/services/toaster.service';
import { ToasterType } from 'src/app/models/toaster.model';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements AfterViewInit, OnInit {
  isMobile = false;
  menuLeft = 0;
  menuTop = 0;
  createUrl = '';
  alerts: AlertNotification[] = [];
  events$ = new BehaviorSubject<any[]>([]);
  calendarOptions: CalendarOptions = {
    height: 'auto',
    initialView: 'timeGridWeek',
    allDaySlot: false,
    expandRows: true,
    slotMinTime: '06:00:00',
    slotMaxTime: '20:00:00',
    plugins: [timeGridPlugin, interactionPlugin],
    locale: enAuLocale,
    datesSet: this.handleWeekChanges.bind(this),
    eventClick: this.handleEventClick.bind(this),
    dateClick: this.handleDateClick.bind(this),
    eventClassNames: ['cursor-pointer'],
    buttonText: {
      today: 'Today'
    },
    titleFormat: {
      year: 'numeric',
      month: 'long'
    },
    headerToolbar: {
      start: 'title',
      center: '',
      end: 'today prev,next'
    },
  };

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger | undefined;
  @ViewChild('calendar') calendarComponent: FullCalendarComponent | undefined;

  constructor(private sessionService: SessionService, private router: Router, private ref: ChangeDetectorRef, private windowService: WindowService, private navService: NavService, private alertService: AlertService, private toasterService: ToasterService) {
    
  }

  ngOnInit(): void {
    this.alertService.getActiveAlerts().subscribe(alerts => {
      this.alerts = alerts;
    });
  }

  ngAfterViewInit(): void {
    this.windowService.isMobile.subscribe(isMobile => {
      this.isMobile = isMobile;
      if (isMobile) {
        this.calendarComponent?.getApi().setOption('height', 'calc(100vh - 64px)');
      } else {
        this.calendarComponent?.getApi().setOption('height', 'calc(100vh - 16px)');
      }
      this.calendarComponent?.getApi().changeView(isMobile ? 'timeGridDay' : 'timeGridWeek');
    });

    this.navService.collapsed.subscribe(() => {
      setTimeout(() => {
        this.calendarComponent?.getApi().updateSize();
      }, 50);
    });
  }

  handleWeekChanges(event: DatesSetArg) {
    const start = event.startStr.split('T')[0];
    const end = event.endStr.split('T')[0];
    this.sessionService.getCalendar(start, end).subscribe(results => {
      const events = results.map(x => { 
        const backgroundColor = x.isPrivate ? x.checkInCount == 0 ? '#F2F4F7' : '#0284c7' : '#ea580c';
        const borderColor = x.isPrivate && x.checkInCount == 0 ? '#D0D5DD' : x.isPrivate ? '#0369a1' : '#c2410c';
        const textColor = x.isPrivate && x.checkInCount == 0 ? '#344054' : '#FFFFFF';
        return { id: x.id, title: x.title, start: `${x.date} ${x.startTime}`, end: `${x.date} ${x.endTime}`, backgroundColor: backgroundColor, borderColor: borderColor, textColor: textColor, isDisabled: x.isPrivate && x.checkInCount == 0 }
      });
      this.events$.next(events);
    });
  }

  handleEventClick(event: EventClickArg) {
    this.router.navigate(['session', event.event.id]);
  }

  handleDateClick(event: DateClickArg) {
    let events = this.events$.value;
    const eventDate = new Date(event.date.getTime());
    events = events.filter((x: any) => !x.isTemp);
    events.push({
      id: 0,
      title: 'New Session',
      start: event.dateStr,
      end: event.date.setHours(new Date(event.date.getTime()).getHours() + 1),
      backgroundColor: 'rgba(2,132,199, 0.5)',
      borderColor: '#0369a1',
      textColor:  '#FFFFFF',
      isTemp: true
    });
    this.events$.next(events);

    const box = event.dayEl.getBoundingClientRect();
    const row = (event.jsEvent.target as HTMLElement)?.getBoundingClientRect();
    const isWeekend = event.date.getDay() === 0 || event.date.getDay() === 6
    this.menuLeft = this.isMobile ? 40 : isWeekend ? box.left - (132 + 5): box.left + (box.width);
    this.menuTop = row.top ? row.top + window.scrollY : row.top + window.scrollY;

    const date = `${eventDate.getFullYear()}-${(eventDate.getMonth() + 1).toString().padStart(2, '0')}-${eventDate.getDate().toString().padStart(2, '0')}`;
    const hour = eventDate.getHours();
    const minute = eventDate.getMinutes();

    this.createUrl = `/session/create?date=${date}&hour=${hour}&minute=${minute}`;
    this.trigger?.openMenu();
  }

  menuStyle() {
    return { left: `${this.menuLeft}px`, top: `${this.menuTop}px`, position: 'absolute', display: 'block' };
  }

  create() {
    this.router.navigateByUrl(this.createUrl);
  }

  cancel() {
    let events = this.events$.value;
    events = events.filter((x: any) => !x.isTemp);
    this.events$.next(events);
  }

  isDisabled(id: number) {
    const events = this.events$.value;

    return events.find(x => x.id == id).isDisabled;
  }

  formatDateHeaderDay(date: string) {
    return moment(date).format('ddd').toUpperCase();
  }

  formatDateHeaderDate(date: string) {
    return moment(date).format('DD');
  }

  onSwipeLeft() {
    this.calendarComponent?.getApi().next();
  }

  onSwipeRight() {
    this.calendarComponent?.getApi().prev();
  }

  onDismissAlert(id: number) {
    this.alertService.dismissAlert(id).subscribe(() => this.alerts = this.alerts.filter(x => x.id !== id));
  }

  onRemindAlert(id: number) {
    this.alertService.remindAlert(id).subscribe(() => { 
      this.alerts = this.alerts.filter(x => x.id !== id);
      this.toasterService.addToaster({
        type: ToasterType.Success,
        message: "We'll remind you in one week."
      });
    });
  }
}
