import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-payment-example-card',
  templateUrl: './payment-example-card.component.html',
  styleUrls: ['./payment-example-card.component.scss']
})
export class PaymentExampleCardComponent {
  @Input('heading') heading: string | undefined;
  @Input({required: true}) amount!: number;
  @Input({required: true}) service!: number;
  @Input({required: true}) chargeFees!: boolean;

  get totalCharged() {
    if (!this.amount) {
      return 0;
    }

    if (!this.service) {
      return this.amount;
    }

    if (this.chargeFees) {
      return this.amount + this.service;
    }

    return this.amount;
  }

  get totalChargedTax() {
    return this.totalCharged * 0.1;
  }

  get earnings() {
    if (this.chargeFees) {
      return this.amount;
    }
    
    return this.amount - this.service;
  }

  get earningsTax() {
    return this.earnings * 0.1;
  }
}
