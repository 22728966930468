import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import SignaturePad from 'signature_pad';
import { StudentDocument } from 'src/app/models/student-document.model';
import { DocumentService } from 'src/app/services/document.service';

@Component({
  selector: 'app-student-document-create-form',
  templateUrl: './student-document-create-form.component.html',
  styleUrls: ['./student-document-create-form.component.scss']
})
export class StudentDocumentCreateFormComponent implements OnInit {
  @Input() studentId: number | null = null;
  @Input() studentDocument: StudentDocument | undefined;

  submitted = false;
  studentDocumentCreateForm!: FormGroup;

  // Todo: add canvas element and use viewchild to reference and connect with signature_pad

  canvas = document.querySelector("canvas");
  signaturePad = new SignaturePad(this.canvas!)

  constructor(private documentService: DocumentService) { }

  ngOnInit(): void {
    
  }

  onSubmit(): void {
    console.log("submit pressed");
  }
}
