import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Observable, forkJoin } from 'rxjs';
import { ConfirmModalComponent } from 'src/app/components/modals/confirm-modal/confirm-modal.component';
import { Discipline } from 'src/app/models/discipline.model';
import { SessionInstance } from 'src/app/models/session-instance.model';
import { StudentSearch } from 'src/app/models/student-search.model';
import { ToasterType } from 'src/app/models/toaster.model';
import { DisciplineService } from 'src/app/services/discipline.service';
import { SessionService } from 'src/app/services/session.service';
import { StudentService } from 'src/app/services/student.service';
import { ToasterService } from 'src/app/services/toaster.service';

@Component({
  selector: 'app-session-create',
  templateUrl: './session-create.component.html',
  styleUrls: ['./session-create.component.scss']
})
export class SessionCreateComponent implements OnInit {
  loading: boolean = true;
  students: StudentSearch[] = [];
  disciplines: Discipline[] = [];
  sessionInstance: SessionInstance | undefined = undefined;
  sessionInstanceId: number | null = null;
  editing: boolean = false;
  date: string | undefined;
  hour: string | undefined;
  minute: string | undefined;

  constructor(private route: ActivatedRoute, private router: Router, private sessionService: SessionService, private studentService: StudentService, private disciplineService: DisciplineService, private dialog: MatDialog, private toasterService: ToasterService) {

  }

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');
    this.date = this.route.snapshot.queryParams['date'];
    this.hour = this.route.snapshot.queryParams['hour'];
    this.minute = this.route.snapshot.queryParams['minute'];

    if (id) {
      this.sessionInstanceId = +id;
      this.editing = true;
      forkJoin([
        this.studentService.getSearchStudents(), 
        this.disciplineService.getDisciplines(), 
        this.sessionService.getSessionInstance(+id)
      ]).subscribe(results => {
        this.students = results[0].filter(student => student.isActive);
        this.disciplines = results[1];
        this.sessionInstance = results[2];
        this.loading = false;
      });
    } else {
      forkJoin([
        this.studentService.getSearchStudents(), 
        this.disciplineService.getDisciplines()
      ]).subscribe(results => {
        console.log(results[0]);
        this.students = results[0].filter(student => student.isActive);
        this.disciplines = results[1];
        this.loading = false;
      })
    }
  }

  delete() {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      width: '400px',
      data: { title: 'Delete session?', content: 'Are you sure you want to delete this session? This cannot be undone.', noText: 'No', yesText: 'Yes' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.sessionService.deleteSessionInstance(this.sessionInstanceId!).subscribe(result => {
          this.toasterService.addToaster({type: ToasterType.Success, message: 'Session deleted'});
          this.router.navigate(['']);
          })
      }
    });
  }
}
