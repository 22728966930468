<alert-notification-bar [alerts]="alerts" (onDismiss)="onDismissAlert($event)" (onRemind)="onRemindAlert($event)"></alert-notification-bar>
<div class="-ml-4 -mr-4 sm:-mr-8 -mb-8" (swipeleft)="onSwipeLeft()" (swiperight)="onSwipeRight()">
<full-calendar #calendar [options]="calendarOptions" [events]="events$ | async">
    <ng-template #eventContent let-arg>
      <div class="h-full overflow-y-hidden touch-action">
        <div class="fc-event-title-container">
            <div class="fc-event-title fc-sticky">{{arg.event.title}}</div>
        </div>
        <div class="fc-event-time" [class.line-through]="isDisabled(arg.event.id)">{{arg.timeText}}</div>
      </div>
    </ng-template>
    <ng-template #dayHeaderContent let-arg>
      <div class="flex flex-col items-center">
        <span [class.text-gray-700]="!arg.isToday" [class.text-primary-700]="arg.isToday" class="text-xs font-normal mb-1">{{formatDateHeaderDay(arg.date)}}</span>
        <div *ngIf="arg.isToday" class="text-center mb-1 flex justify-center items-center w-10 h-10 rounded-full bg-primary-700">
          <span class="text-center text-2xl font-semibold text-white">{{formatDateHeaderDate(arg.date)}}</span>
        </div>
        <div *ngIf="!arg.isToday" class="h-10 w-10 flex justify-center items-center">
          <span class="text-2xl font-semibold  text-gray-700">{{formatDateHeaderDate(arg.date)}}</span>
        </div>
      </div>
    </ng-template>
    <ng-template #slotLabelContent let-arg>
      <div class="h-full flex flex-col">
        <div class=" relative text-xs font-normal">{{arg.text}}</div>
      </div>
    </ng-template>
  </full-calendar>
  <button class="invisible" mat-button [matMenuTriggerFor]="menu" [style]="menuStyle()" (menuClosed)="cancel()">Menu</button>
  <mat-menu #menu="matMenu" >
    <div class="flex justify-end px-2">
      <button type="button" (click)="create()" class="text-white border border-blue-600 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-1.5 text-center mr-2">Create</button>
      <button type="button" (click)="cancel()" class="focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-2 py-2 text-center text-gray-500 hover:text-white border border-gray-500 hover:bg-gray-600">
        <trash-outline-icon class="cursor-pointer h-5 w-5" aria-hidden="true"></trash-outline-icon>
      </button>
  </div>
  </mat-menu>
</div>