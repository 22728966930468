import { Component, OnInit } from '@angular/core';
import { StudentDocument } from 'src/app/models/student-document.model';

@Component({
  selector: 'app-student-document-create',
  templateUrl: './student-document-create.component.html',
  styleUrls: ['./student-document-create.component.scss']
})
export class StudentDocumentCreateComponent implements OnInit {
  loaded: boolean = false;
  studentDocument: StudentDocument | undefined;
  // todo: when the API to retrieves the StudentDocument it should insert the
  // required data into the placeholder links i.e {student_name} 

  ngOnInit(): void {
    
  }

}
