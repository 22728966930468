import { MAT_NATIVE_DATE_FORMATS, MatDateFormats } from "@angular/material/core";
import { DateTime } from "luxon";
import * as moment from "moment";
import "moment-timezone";



export const CUSTOM_DATE_FORMATS: MatDateFormats = {
  ...MAT_NATIVE_DATE_FORMATS,
  display: {
    ...MAT_NATIVE_DATE_FORMATS.display,
    dateInput: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    } as Intl.DateTimeFormatOptions,
  }
};


export function utcToLocalDate(
  date: DateTime
): string {
  return moment(date).tz(moment.tz.guess()).format('DD/MM/YYYY');
}