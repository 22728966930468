import { NgModule } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthModule } from '@auth0/auth0-angular';
import { AuthHttpInterceptor } from '@auth0/auth0-angular';
import { FullCalendarModule } from '@fullcalendar/angular';
import { NgHeroiconsModule } from "@dimaslz/ng-heroicons";
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatExpansionModule } from '@angular/material/expansion';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SideNavigationComponent } from './components/side-navigation/side-navigation.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { AvatarComponent } from './components/avatar/avatar.component';
import { SessionCreateComponent } from './pages/session/session-create/session-create.component';
import { SessionCreateFormComponent } from './components/forms/session/session-create-form/session-create-form.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { StudentCreateComponent } from './pages/student/student-create/student-create.component';
import { StudentCreateFormComponent } from './components/forms/student/student-create-form/student-create-form.component';
import { StudentIndexComponent } from './pages/student/student-index/student-index.component';
import { SaveFooterComponent } from './components/save-footer/save-footer.component';
import { StudentIdComponent } from './pages/student/student-id/student-id.component';
import { StudentGeneralComponent } from './pages/student/student-id/student-general/student-general.component';
import { StudentTrainingComponent } from './pages/student/student-id/student-training/student-training.component';
import { StudentNotesComponent } from './pages/student/student-id/student-notes/student-notes.component';
import { StudentHistoryComponent } from './pages/student/student-id/student-history/student-history.component';
import { StudentMembershipDetailsComponent } from './pages/student/student-id/student-membership/student-membership-details/student-membership-details.component';
import { StudentDocumentsComponent } from './pages/student/student-id/student-documents/student-documents.component';
import { BackButtonComponent } from './components/back-button/back-button.component';
import { LoaderComponent } from './components/loader/loader.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SessionIdComponent } from './pages/session/session-id/session-id.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ConfirmModalComponent } from './components/modals/confirm-modal/confirm-modal.component';
import { SettingsIndexComponent } from './pages/settings/settings-index/settings-index.component';
import { SettingsGeneralComponent } from './pages/settings/settings-general/settings-general.component';
import { SettingsAlertsComponent } from './pages/settings/settings-alerts/settings-alerts.component';
import { SettingsDisciplinesComponent } from './pages/settings/settings-disciplines/settings-disciplines.component';
import { MatTooltipModule} from '@angular/material/tooltip';
import { SettingsGeneralFormComponent } from './components/forms/settings/settings-general-form/settings-general-form.component';
import { SettingsAlertsFormComponent } from './components/forms/settings/settings-alerts-form/settings-alerts-form.component';
import { NoteCreateModalComponent } from './components/modals/note-create-modal/note-create-modal.component';
import { UploadImageModalComponent } from './components/modals/upload-image-modal/upload-image-modal.component';
import { AngularCropperjsModule } from 'angular-cropperjs';
import { SideNavigationMinimisedComponent } from './components/side-navigation-minimised/side-navigation-minimised.component';
import { CUSTOM_DATE_FORMATS } from './lib/date-format';
import { TrainingCardComponent } from './components/training-card/training-card.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { StudentTrainingCreateModalComponent } from './components/modals/student-training-create-modal/student-training-create-modal.component';
import { CommonModule, CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { ButtonPrimaryComponent } from './components/button-primary/button-primary.component';
import { SuccessToasterComponent } from './components/toasters/success-toaster/success-toaster.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { ErrorToasterComponent } from './components/toasters/error-toaster/error-toaster.component';
import { CheckInModalComponent } from './components/modals/check-in-modal/check-in-modal.component';
import { environment } from 'src/environments/environment';
import { MobileNavigationComponent } from './components/mobile-navigation/mobile-navigation.component';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import 'hammerjs';
import { SettingsDocumentsComponent } from './pages/settings/settings-documents/settings-documents.component';
import { DocumentCreateFormComponent } from './components/forms/settings/document-create-form/document-create-form.component';
import { SettingsDocumentCreateComponent } from './pages/settings/settings-documents/settings-document-create/settings-document-create.component';
import { EditorWrapperComponent } from './components/editor-wrapper/editor-wrapper.component';
import { AlertNotificationBarComponent } from './components/alert-notification-bar/alert-notification-bar.component';
import { StudentDocumentCreateFormComponent } from './components/forms/student/student-document-create-form/student-document-create-form.component';
import { StudentDocumentCreateComponent } from './pages/student/student-id/student-documents/student-document-create/student-document-create.component';
import { SettingsMembershipsComponent } from './pages/settings/settings-memberships/settings-memberships.component';
import { SettingsMembershipCreateComponent } from './pages/settings/settings-memberships/settings-membership-create/settings-membership-create.component';
import { MembershipCreateFormComponent } from './components/forms/settings/membership-create-form/membership-create-form.component';
import { PaymentExampleCardComponent } from './components/forms/settings/membership-create-form/payment-example-card/payment-example-card.component';
import { StudentMembershipComponent } from './pages/student/student-id/student-membership/student-membership.component';
import { StudentMembershipCreateModalComponent } from './components/modals/student-membership-create-modal/student-membership-create-modal.component';

@NgModule({
  declarations: [
    AppComponent,
    SideNavigationComponent,
    DashboardComponent,
    AvatarComponent,
    SessionCreateComponent,
    SessionCreateFormComponent,
    StudentCreateComponent,
    StudentCreateFormComponent,
    StudentIndexComponent,
    SaveFooterComponent,
    StudentIdComponent,
    StudentGeneralComponent,
    StudentTrainingComponent,
    StudentNotesComponent,
    StudentHistoryComponent,
    StudentMembershipDetailsComponent,
    StudentDocumentsComponent,
    BackButtonComponent,
    LoaderComponent,
    SessionIdComponent,
    ConfirmModalComponent,
    SettingsIndexComponent,
    SettingsGeneralComponent,
    SettingsAlertsComponent,
    SettingsDisciplinesComponent,
    SettingsGeneralFormComponent,
    SettingsAlertsFormComponent,
    NoteCreateModalComponent,
    UploadImageModalComponent,
    SideNavigationMinimisedComponent,
    TrainingCardComponent,
    StudentTrainingCreateModalComponent,
    ButtonPrimaryComponent,
    SuccessToasterComponent,
    ErrorToasterComponent,
    CheckInModalComponent,
    MobileNavigationComponent,
    SettingsDocumentsComponent,
    DocumentCreateFormComponent,
    SettingsDocumentCreateComponent,
    EditorWrapperComponent,
    AlertNotificationBarComponent,
    StudentDocumentCreateFormComponent,
    StudentDocumentCreateComponent,
    SettingsMembershipsComponent,
    SettingsMembershipCreateComponent,
    MembershipCreateFormComponent,
    PaymentExampleCardComponent,
    StudentMembershipComponent,
    StudentMembershipCreateModalComponent,
  ],
  imports: [
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    AuthModule.forRoot({
      domain: environment.auth0Domain,
      clientId: environment.auth0ClientId,
      authorizationParams: {
        audience: 'fightcloud',
        redirect_uri: window.location.origin
      },
      httpInterceptor: {
        allowedList: [
          '*'
        ]
      }
    }),
    FullCalendarModule,
    NgHeroiconsModule,
    NgxMaterialTimepickerModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    MatDialogModule,
    MatTooltipModule,
    AngularCropperjsModule,
    MatSlideToggleModule,
    CommonModule,
    MatMenuModule,
    MatSnackBarModule,
    MatExpansionModule,
    HammerModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'en-AU' },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
    DatePipe,
    { provide: HAMMER_GESTURE_CONFIG, useClass: HammerGestureConfig },
    DecimalPipe,
    CurrencyPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
