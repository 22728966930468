import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Membership } from 'src/app/models/membership.model';

@Component({
  selector: 'app-student-membership-create-modal',
  templateUrl: './student-membership-create-modal.component.html',
  styleUrls: ['./student-membership-create-modal.component.scss']
})
export class StudentMembershipCreateModalComponent {
  selectedMembership: Membership | undefined;
  content: string | undefined;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { memberships: Membership[] }) { 
    if (data.memberships && data.memberships.length > 0) {
      this.selectedMembership = data.memberships[0];
    }
  }
}
