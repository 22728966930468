<div class="inline-block">
  <app-back-button></app-back-button>
</div>
<div class="mt-2 gap-3 items-center flex" *ngIf="!loading">
  <app-avatar [imageUrl]="student?.imageUrl"
    initials="{{student?.firstName.charAt(0).toUpperCase()}}{{student?.lastName.charAt(0).toUpperCase()}}"></app-avatar>
  <h1 class="text-3xl font-medium text-gray-900">
    {{ student?.firstName }} {{ student?.lastName }} <span *ngIf="!student?.isActive">(Archived)</span>
  </h1>
</div>
<div class="mt-1.5">
  <div class="mt-4 pb-4 flex border-b border-gray-200 sm:hidden">
    <mat-select [(ngModel)]="currentRoute" (selectionChange)="routeChange($event)" [hideSingleSelectionIndicator]="true" class="border border-gray-300 focus:border-primary-300 focus:outline-0 focus:ring focus:ring-primary-100 text-sm pr-4 py-[10px] rounded-lg w-full hover:bg-gray-50">
      <mat-select-trigger>
      <span class="pl-4">
          {{currentRoute.title}}
      </span>
      </mat-select-trigger>
      <mat-option *ngFor="let route of routes" [value]="route" class="-ml-2">{{route.title}}</mat-option>
    </mat-select>
  </div>
  <ul
    class="hidden sm:flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400">
    <li class="mr-4">
      <a [routerLink]="['/student', student?.id, 'general']"
        routerLinkActive="text-primary-700 border-b-2 border-primary-700"
        class="inline-block px-1 py-4   hover:text-primary-700 hover:border-b-2 hover:border-primary-700">
        General
      </a>
    </li>
    <li class="mr-4">
      <a [routerLink]="['/student', student?.id, 'training']"
        routerLinkActive="text-primary-700 border-b-2 border-primary-700"
        class="inline-block px-1 py-4   hover:text-primary-700 hover:border-b-2 hover:border-primary-700">
        Training
      </a>
    </li>
    <li class="mr-4">
      <a [routerLink]="['/student', student?.id, 'notes']"
        routerLinkActive="text-primary-700 border-b-2 border-primary-700"
        class="inline-block px-1 py-4   hover:text-primary-700 hover:border-b-2 hover:border-primary-700">
        Notes
      </a>
    </li>
    <li class="mr-4">
      <a [routerLink]="['/student', student?.id, 'history']"
        routerLinkActive="text-primary-700 border-b-2 border-primary-700"
        class="inline-block px-1 py-4   hover:text-primary-700 hover:border-b-2 hover:border-primary-700">
        History
      </a>
    </li>
    <li class="mr-4">
      <a [routerLink]="['/student', student?.id, 'documents']"
        routerLinkActive="text-primary-700 border-b-2 border-primary-700"
        class="inline-block px-1 py-4  hover:text-primary-700 hover:border-b-2 hover:border-primary-700">
        Documents
      </a>
    </li>
    <li class="mr-4">
      <a [routerLink]="['/student', student?.id, 'membership']"
        routerLinkActive="text-primary-700 border-b-2 border-primary-700"
        class="inline-block px-1 py-4   hover:text-primary-700 hover:border-b-2 hover:border-primary-700">
        Membership
      </a>
    </li>
  </ul>
  <router-outlet></router-outlet>
</div>