<form [formGroup]="form" (ngSubmit)="confirmDialog()">

  <h1 class="select-none text-lg font-semibold text-gray-900 mb-2 pt-6 px-6">
    Add discipline
  </h1>

  <div class="select-none px-6 mb-2">

    <div class="flex flex-col gap-1.5">
      <label class="text-sm font-medium text-gray-700">Discipline</label>
      <mat-select formControlName="discipline"
        [ngClass]="{'pr-4 py-[10px] rounded-lg w-full border focus:outline-0 focus:ring text-sm hover:bg-gray-50': true, 'border-error-300 focus:border-error-300 focus:ring-error-300': submitted && !form.get('discipline')?.valid ?? false, 'border-gray-300 focus:border-primary-300 focus:ring-gray-100': !submitted || form.get('discipline')?.valid ?? true}">
        <mat-select-trigger>
          <span class="pl-4">
            {{ form.get('discipline')?.value?.name }}
          </span>
        </mat-select-trigger>
        <mat-option *ngFor="let discipline of data.disciplines" [value]="discipline"
          class="-ml-2">{{discipline.name}}</mat-option>
      </mat-select>
      <span *ngIf="submitted && !form.get('discipline')?.valid ?? false" class="text-error-500">Discipline is required</span>
    </div>

    <div class="flex flex-col gap-1.5 mt-4">
      <label class="text-sm font-medium text-gray-700">Rank</label>
      <mat-select formControlName="grade"
        [ngClass]="{'pr-4 py-[10px] rounded-lg w-full border focus:outline-0 focus:ring text-sm hover:bg-gray-50': true, 'border-error-300 focus:border-error-300 focus:ring-error-300': submitted && !form.get('grade')?.valid ?? false, 'border-gray-300 focus:border-primary-300 focus:ring-gray-100': !submitted || form.get('grade')?.valid ?? true}">
        <mat-select-trigger>
          <span class="pl-4">
            {{ form.get('grade')?.value?.gradeName }}
          </span>
        </mat-select-trigger>
        <mat-option *ngFor="let grade of form.get('discipline')?.value?.grades" [value]="grade"
          class="-ml-2">{{grade.gradeName}}</mat-option>
      </mat-select>
      <span *ngIf="submitted && !form.get('grade')?.valid ?? false" class="text-error-500">Rank is required</span>
    </div>

    <div class="flex flex-col gap-1.5 mt-4">
      <div class="flex items-center">
        <mat-label class="text-m font-medium text-gray-700">Manage rank?</mat-label>
        <mat-slide-toggle formControlName="manageGrade" class="ml-4 relative inline-flex items-center rounded-full transition-colors"
          [color]="'primary'" [disableRipple]="true">
        </mat-slide-toggle>
      </div>
    </div>

    <div class="flex flex-col gap-1.5 mt-4">
      <label class="text-sm font-medium text-gray-700">Rank date</label>
      <div class="relative">
        <input formControlName="gradeDate" (click)="date.open()" [matDatepicker]="date" (dateChange)="dateChange($event)"  
        [ngClass]="{'w-full text-sm px-4 py-3 rounded-lg border focus:outline-0 focus:ring hover:bg-gray-50': true, 'border-error-300 focus:border-error-300 focus:ring-error-300': submitted && !form.get('gradeDate')?.valid ?? false, 'border-gray-300 focus:border-primary-300 focus:ring-gray-100': !submitted || form.get('gradeDate')?.valid ?? true}"/>
        <calendar-outline-icon class="absolute right-3 top-2.5 w-6 text-gray-500 pointer-events-none"></calendar-outline-icon>
        <mat-datepicker  touchUi #date></mat-datepicker>
      </div>
      <span *ngIf="submitted && !form.get('gradeDate')?.valid ?? false" class="text-error-500">Rank date is required</span>
    </div>

  </div>

  <div class="flex pt-4 pb-6 px-6 gap-3">
    <button
      type="button"
      class="h-[38px] grow text-gray-700 border border-gray-300 hover:bg-gray-50 focus:ring focus:ring-gray-100 focus:outline-0 font-medium rounded-lg text-sm px-3 py-1.5 text-center"
      (click)="closeDialog()">
      Cancel
    </button>
    <button
      type="submit"
      class="h-[38px] grow bg-primary-600 text-white border border-primary-300 hover:bg-primary-70 focus:ring focus:ring-error-100 focus:outline-0 font-medium rounded-lg text-sm px-3 py-1.5 text-center">
      Confirm
    </button>
  </div>

</form>