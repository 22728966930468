import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ConfirmModalComponent } from 'src/app/components/modals/confirm-modal/confirm-modal.component';
import { ToasterType } from 'src/app/models/toaster.model';
import { StudentService } from 'src/app/services/student.service';
import { ToasterService } from 'src/app/services/toaster.service';

@Component({
  selector: 'app-student-general',
  templateUrl: './student-general.component.html',
  styleUrls: ['./student-general.component.scss']
})
export class StudentGeneralComponent implements OnInit {
  student: any;
  loaded: boolean = false;
  #studentId: number | undefined;

  constructor(private route: ActivatedRoute, private studentService: StudentService, private dialog: MatDialog, private toasterService: ToasterService) {}

  ngOnInit(): void {
    this.route.parent?.params.subscribe(params => {
      this.#studentId = params['id']
      this.#loadData();
    });
  }

  archive() {

    if (this.student.activeMembership) {
      const dialogRef = this.dialog.open(ConfirmModalComponent, {
        width: '400px',
        data: { title: 'This student cannot be archived.', content: `${this.student.firstName} has an active membership. Please cancel it before archiving them.`,  yesText: 'Ok' }
      });
      return;
    }

    const title = this.student.isActive ? 'Archive student?' : 'Unarchive student?';
    const content = this.student.isActive ? `Are you sure you want to archive ${this.student.firstName}?` : `Are you sure you want to unarchive ${this.student.firstName}?`;
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      width: '400px',
      data: { title: title, content: content, noText: 'No', yesText: 'Yes' }
    });

    dialogRef.afterClosed().subscribe(result => {
      const message = this.student.isActive ? 'Successfully archived student' : 'Successfully unarchived student';
      if (result && this.#studentId) {
        this.studentService.archiveStudent(this.#studentId).subscribe({
          next: () => {
            this.toasterService.addToaster({ type: ToasterType.Success, message: message });
            this.#loadData();
          },
          error: (error) => {
            this.toasterService.addToaster({ type: ToasterType.Error, message: error.error });
          }
        });
      }
    });
  }

  #loadData() {
    if (this.#studentId) {
      this.studentService.getStudent(this.#studentId).subscribe(response => {
        this.student = response;
        this.loaded = true;
      })
    }
  }
}
