import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { initFlowbite } from 'flowbite';
import { Observable } from 'rxjs';
import { WindowService } from './services/window.service';
import { NavService } from './services/nav.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  collapsed = false;
  isAuthenticated$: Observable<boolean>;
  isMobile = false;

  constructor(private authService: AuthService, private windowService: WindowService, private navService: NavService) {
    this.isAuthenticated$ = this.authService.isAuthenticated$;
  }

  ngOnInit(): void {
    initFlowbite();
    this.windowService.isMobile.subscribe(isMobile => this.isMobile = isMobile);
  }

  collapse(value: boolean) {
    this.collapsed = value;
    this.navService.setCollapsed(value);
  }
}
