
<div class="flex items-center justify-end align-end gap-3 top-0 right-8 absolute pt-16 sm:pt-8">
    <div *ngIf="canAddDisciplines" (click)="addTraining()"
        class="h-[30px] sm:h-[38px] select-none hover:cursor-pointer text-white border border-primary-600 bg-primary-600 hover:bg-primary-700 focus:ring focus:ring-primary-100 focus:outline-0 font-medium rounded-lg text-sm pl-2 pr-4 py-2.5 flex items-center space-x-2">
        <plus-small-outline-icon svgClass="heroicon-sw-1.5" [size]="24"></plus-small-outline-icon>
        <span class="hidden sm:flex">
            Add discipline
        </span>
        <span class="flex sm:hidden">
            Add
        </span>
    </div>
</div>
<div class="w-full mt-8">
    <div *ngFor="let grade of studentGrades" class="mb-6">
        <app-training-card [grade]="grade" [gradeOptions]="getGradesByDiscipline(grade.disciplineId)"></app-training-card>
    </div>
</div>

