import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AlertNotification } from '../models/alert.model';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(private http: HttpClient) { }

  getActiveAlerts() {
    return this.http.get<AlertNotification[]>(`${environment.baseUrl}/alert`);
  }

  dismissAlert(id: number) {
    return this.http.post(`${environment.baseUrl}/alert/dismiss/${id}`, null);
  }

  remindAlert(id: number) {
    return this.http.post(`${environment.baseUrl}/alert/remind/${id}`, null);
  }
}
