import { Component, OnInit } from '@angular/core';
import { GymService } from 'src/app/services/gym.service';

@Component({
  selector: 'app-settings-general',
  templateUrl: './settings-general.component.html',
  styleUrls: ['./settings-general.component.scss']
})
export class SettingsGeneralComponent implements OnInit {
  gym: any;
  loaded: boolean = false;

  constructor(private gymService: GymService) {}

  ngOnInit(): void {
    this.gymService.getGym().subscribe(response => {
      this.gym = response;
      this.loaded = true;
    })
  }
}
